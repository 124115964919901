import * as THREE from 'three'

import Experience from '../Experience.js'
import Environment from './Environment.js'
import EventEmitter from '../Utils/EventEmitter.js'
import Floor from './Floor.js'
import Prop from './Prop.js'
import characters from "../Constants/character.js"
import { Mesh } from 'three'
import CharacterTest from './CharacterTest.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { Water} from 'three/examples/jsm/objects/Water2.js';

export default class World extends EventEmitter
{
    constructor()
    {
        super()
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.shading = this.experience.shading
        this.transformableObjectArray = []
        this.shadeableObjectArray = []

        // this.gltfLoader = new GLTFLoader(loadingManager)
        this.object_added = null
        this.setLoadingScreen()

        // this.newWall = new Walls()
        // this.newRoom = new Floor()
        // this.newRoom.on('ready', () =>
        // {
        //     this.transformableObjectArray.push(this.newRoom.mesh)
        //     this.shadeableObjectArray.push(this.newRoom.mesh)
        // })

        // this.transformableObjectArray.push(this.newRoom.mesh)
        // this.shadeableObjectArray.push(this.newRoom.mesh)
    
        // Wait for resources
        this.resources.on('ready', () =>
        {
            // this.floor = new Floor()
            // this.shadeableObjectArray.push(this.floor.model)
            // Setup
            this.environment = new Environment()
            // this.transformableObjectArray.push(this.environment.sunGizmo)

            // world is fully loaded
            this.trigger('ready')
        })
    }

    setLoadingScreen(){
        const loadingBarElement = document.querySelector('.loading-bar')
        const loadingBgElement = document.querySelector('.loading-background')
        loadingBarElement.classList.add('ended')
        loadingBgElement.classList.add('ended')
        this.loadingManager = new THREE.LoadingManager(
            // Loaded
            () =>
            {
                window.setTimeout(() =>
                {
                    loadingBarElement.classList.add('ended')
                        loadingBgElement.classList.add('ended')
                        loadingBarElement.style.transform = ''
                }, 200)
            },
        
            // Progress
            (itemUrl, itemsLoaded, itemsTotal) =>
            {
                const progressRatio = itemsLoaded / itemsTotal
                loadingBarElement.style.transform = `scaleX(${progressRatio})`
            }
        )
        this.loadingManager.onStart = function ( url, itemsLoaded, itemsTotal ) {
            loadingBarElement.classList.remove('ended')
            loadingBgElement.classList.remove('ended')
            loadingBgElement.innerHTML = 'Grabbing your scene ...'
        };
        this.gltfLoader = new GLTFLoader(this.loadingManager)
    }

    addWallToScene(
        name,
        path,
		position = null,
		scale = null,
		rotation = null,
        background = false,
        lighten_color = false
    ){
        this.experience.shading.resetShading()

        const newFloor = new Floor(path)

        newFloor.on('ready', () =>
        {
            this.transformableObjectArray.push(newFloor.mesh)
            this.shadeableObjectArray.push(newFloor.mesh)
            this.experience.transform.select(newFloor.mesh)
            this.object_added = newFloor.mesh
            this.trigger(name + ' ready')
            this.trigger('ready')
        })
		return newFloor

    }

    addModelToScene(
        name,
        path,
		position = null,
		scale = null,
		rotation = null,
        background = false,
        lighten_color = false,
        texture_gcs_folder = null) {
        // undo shading because we'll add one more objs to shadeable object array
        this.experience.shading.resetShading()

		const newObj = new Prop(name,path,position,scale,rotation,lighten_color,null,texture_gcs_folder)

        newObj.on('ready', () =>
        {
            if (!background) {this.transformableObjectArray.push(newObj.model)}
            if (name!='basketball') {this.shadeableObjectArray.push(newObj.model)}
            this.experience.transform.select(newObj.model)
            this.object_added = newObj.model
            this.trigger(name + ' ready')
            this.trigger('ready')
            // newObj.on( 'change', this.experience.rendermanager.render.bind(this) );
        })
		return newObj
	}

    addModelsToScene(
        name,
        path,
		position = null,
		scale = null,
		rotation = null,
        background = false,
        lighten_color = false) {
        // undo shading because we'll add one more objs to shadeable object array
        this.experience.shading.resetShading()

		this.gltfLoader.load(
            path,
            (file) =>
            {
                var lights = []
                this.scene_to_load = file.scene
                this.scene.add(this.scene_to_load)
                // console.log(this.scene_to_load)
                this.transformableObjectArray.push(this.scene_to_load)
                this.shadeableObjectArray.push(this.scene_to_load)

                var objs_to_remove = []
                var numChildrenMesh = 0
                this.scene_to_load.traverse((child) =>
                {
                    if (child.material){
                        numChildrenMesh = numChildrenMesh +1
                    }
                    if(child.parent)
                    {
                        if (child.parent === this.scene_to_load & !(child instanceof THREE.DirectionalLight) & !(child instanceof THREE.PerspectiveCamera))

                        {
                            // console.log(child)
   
                            var newObj = new Prop(child.name,null,position,scale,rotation,lighten_color, child, null)

                            //below not working yet
                            newObj.on('ready', () =>
                            {
                                // console.log('si ready now')
                                // this.transformableObjectArray.push(newObj.model)
                            //     console.log('helloooo')
                                // this.shadeableObjectArray.push(newObj.model)
                                // console.log(this.shadeableObjectArray)
                            //     newObj.on( 'change', this.experience.rendermanager.render.bind(this) );
                            })
                        }
                        if ((child instanceof THREE.DirectionalLight) || (child instanceof THREE.PerspectiveCamera))
                        {
                            // console.log('need to remove',child)
                            objs_to_remove.push(child)
                        }
                    }
                })
                this.scene_to_load.userData.numChildrenMesh = numChildrenMesh
                objs_to_remove.forEach(item => {
                    if (item.parent)
                    {
                        item.parent.remove(item)
                    }
                })
                // console.log(this.scene_to_load)
                this.object_added = this.scene_to_load
                this.trigger('ready')
            })
    }

    addCharacterToScene(id, name, path, scale, jointScale, defaultPose) {
        // undo shading because we'll add one more char to shadeable object array
        this.experience.shading.resetShading()

        if (this.experience.poseManager.jointsVisible)
        {
            this.experience.poseManager.poseJoints()
        }

		const newCharacter = new CharacterTest(id, name, path, scale, jointScale, defaultPose)

        newCharacter.on('ready', () =>
        {
            // this.transformableObjectArray.push(newCharacter.object)
            this.shadeableObjectArray.push(newCharacter.object)
            // console.log(this.shadeableObjectArray)
            newCharacter.on( 'change', this.experience.rendermanager.render.bind(this) );
            
            this.experience.poseManager.selectCharacter(newCharacter)
            this.experience.poseManager.characters.push(newCharacter)
            this.experience.poseManager.poseJoints()
            this.experience.transform.select(newCharacter.hip)
            this.object_added = newCharacter.hip
            this.trigger(name + ' ready')
            this.trigger('ready')
        })
		return newCharacter
	}

    buildWater(url) {
        if (this.water){
            return
        }
        this.experience.shading.resetShading()

        const waterGeometry = new THREE.PlaneGeometry( 1000, 1000 );

        this.water = new Water( waterGeometry, {
            color: '#ffffff',
            scale: 15,
            flowDirection: new THREE.Vector2( 1, 1),
            textureWidth: 1024,
            textureHeight: 1024,
            normalMap0: new THREE.TextureLoader().load( url, function ( texture ) {

                            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        
                        } ),
            normalMap1: new THREE.TextureLoader().load( url, function ( texture ) {

                            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        
                        } ),
        } );

        this.water.name = 'water'
        this.water.position.y = 0
        this.water.rotation.x = Math.PI * - 0.5;
        this.scene.add( this.water );
        this.object_added = this.water
        this.trigger('water ready')
        this.trigger('ready')
        // this.water.userData.parent = this.water
        // this.experience.world.transformableObjectArray.push(this.water);
        this.experience.renderer.update()
    }

    removeWater(){
        if (this.water){
                //destroy old skybox
                // Loop through the material properties
                for(const key in this.water.material)
                {
                    const value = this.water.material[key]
    
                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
                this.scene.remove(this.water)
                this.water.geometry.dispose();
                this.water = undefined
                this.experience.renderer.update()
        }
    }

    purgeTransformableObjectArray(){
        var arr = [1, 2, 3, 4, 5];
 
        for (var i = this.transformableObjectArray.length - 1; i >= 0; i--) {
            if (this.transformableObjectArray[i] ==null || this.transformableObjectArray[i].parent == null){
                console.log('removing from transformableObjectArray:',i)
                this.transformableObjectArray = this.transformableObjectArray.splice(i,1)
            }
        }
    }
}