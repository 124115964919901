const aastep = `
float aastep(float threshold, float value) {
//   #ifdef GL_OES_standard_derivatives
    float afwidth = length(vec2(dFdx(value), dFdy(value))) * 0.70710678118654757;
    return smoothstep(threshold-afwidth, threshold+afwidth, value);
//   #else
//     return step(0.1, value);
//   #endif  
}`;

export { aastep };
