import * as dat from 'lil-gui'
import Time from './Time.js'
import Stats from 'stats.js'

export default class Debug
{
    constructor()
    {
        this.showStats = window.location.hash === '#stats'

        if(this.showStats)
        {
            const stats = new Stats()
            stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
            document.body.appendChild(stats.dom)

            // Time tick event
            this.time = new Time()
            this.time.on('tick', () =>
            {
                stats.begin()
                stats.end()
                // this.update()
            })
        }

        //this.active = window.location.hash === '#debug'
        this.active = true
        this.visible = true
        
        if(this.active)
        {
            this.ui = new dat.GUI()
            this.ui.domElement.id = 'control_panel'
            this.ui.hide()
            this.visible = false
        }
    }
}