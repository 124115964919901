import * as THREE from 'three'
import { DoubleSide } from 'three'
import Experience from '../Experience.js'
import GUI from 'lil-gui'
import EventEmitter from '../Utils/EventEmitter.js'
import { ToonMaterial } from '../materials/toonMaterial.js';


export default class Floor extends EventEmitter
{
    constructor(path)
    {
        super()

        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.textures = {}
        this.gui = new GUI();
        this.gui.domElement.id = 'control_panel'

        // this.gui.hide()

        const loader = new THREE.TextureLoader();

        // load a resource
        loader.load(
            // resource URL
            path,

            // onLoad callback
            function ( texture ) {
                this.textures.color = texture
                this.textures.color.encoding = THREE.sRGBEncoding
                this.textures.color.repeat.set(1, 1)
                this.textures.color.wrapS = THREE.RepeatWrapping
                this.textures.color.wrapT = THREE.RepeatWrapping

                this.setGeometry()
                this.setMaterial()
                this.setMesh()
                this.experience.update()

                const myObject = {
                    x: 1,
                    y: 1,
                    rotX: 0
                };

                // Chainable methods
                this.gui.add( myObject, 'x' ).min(0.1).max(10).step(0.1)
                    .name( 'repeatHorizontal' )
                    .onChange( value => {
                        this.textures.color.repeat.x = value
                        this.experience.update()
                    } );

                this.gui.add( myObject, 'y' ).min(0.1).max(10).step(0.1)
                    .name( 'repeatVertical' )
                    .onChange( value => {
                        this.textures.color.repeat.y = value
                        this.experience.update()
                    } );

                var params = {
                        color: '#ffffff',
                        overlayColor: '#ffffff',
                        ResetColor: function() {
                            this.plane.material.map = this.textures.color
                            this.plane.material.color.set(0xffffff)
                            this.plane.material.needsUpdate = true
                            this.plane.userData.animeMaterial.uniforms.uColor.value.copy(this.plane.material.color)
                            this.plane.userData.animeMaterial.needsUpdate = true
                            this.experience.update()
                        }.bind(this),
                };
                    
                this.gui.addColor( params, 'color' ).name('Color').onChange(value => {
                    this.plane.material.map = null
                    this.plane.material.needsUpdate = true
                    this.plane.material.color.set(value)
                    this.plane.userData.animeMaterial.map = null
                    this.plane.userData.animeMaterial.uniforms.uColor.value.copy(this.plane.material.color)
                    this.plane.userData.animeMaterial.needsUpdate = true
                    this.experience.update()
                });

                this.gui.addColor( params, 'overlayColor' ).name('overlayColor').onChange(value => {
                    this.plane.material.needsUpdate = true
                    this.plane.material.map = this.plane.userData.originalTexture
                    this.plane.material.color.set(value)
                    this.experience.update()
                }).onFinishChange(value => {
                    this.plane.userData.animeMaterial.copy(this.plane.userData.originalMaterial);
                    this.plane.userData.animeMaterial.needsUpdate = true
                    this.experience.update()
                });

                this.gui.add( params, 'ResetColor' ); 

                this.trigger('ready')

            }.bind(this),

            // onProgress callback currently not supported
            undefined,

            // onError callback
            function ( err ) {
                console.error( 'An error happened.' );
            }
        );
    }

    setGeometry()
    {
        this.geometry = new THREE.PlaneGeometry(1,1)
    }

    setTextures()
    {

        // this.ui.add(this.textures.color.repeat,'number',0,100,1)

        // this.textures.normal = this.resources.items.grassNormalTexture
        // this.textures.normal.repeat.set(1.5, 1.5)
        // this.textures.normal.wrapS = THREE.RepeatWrapping
        // this.textures.normal.wrapT = THREE.RepeatWrapping
    }

    setMaterial()
    {
        this.material = new THREE.MeshStandardMaterial({
            // color: new THREE.Color( 'white'),
            side: THREE.DoubleSide,
            flatShading: true,
            fog:false,
            map: this.textures.color
            // normalMap: this.textures.normal
        })
    }

    setMesh()
    {
        this.mesh = new THREE.Group()

        this.plane = new THREE.Mesh(this.geometry, this.material)
        this.plane.userData.parent = this.mesh
        this.plane.userData.gui = this.gui
        this.plane.receiveShadow = true
        this.plane.position.y = 0.5
        this.plane.position.x = 0.5

        this.plane.userData.originalMaterial = this.plane.material
        this.plane.userData.originalTexture = this.plane.material.map
        this.plane.userData.animeMaterial = new ToonMaterial({colors: this.experience.shading.colors});
        this.plane.userData.animeMaterial.copy(this.plane.material);

        this.mesh.add(this.plane)
        this.scene.add(this.mesh)
    }

    hideUI()
    {
        this.gui.hide()
    }

    showUI()
    {
        this.gui.show()
    }
}