import * as THREE from 'three'
import poseLib from '../Constants/poseLibrary.js'
import Joint, { jointColors } from "../Utils/Joint.js"

export default class PoseManager
{
    constructor()
    {
        this.characters = []
        this.selectedCharacter = null
        this.faceGuiOn = false
        this.jointsVisible = false

        var text =
        {
            poses: 'default',
            joints_visible: false
        }

		var poseDict = {}
		var n=0
		for (const entry of Object.entries(poseLib.body)) {
			poseDict[n] = entry[1].name
			n ++
		}

		// add pose names to pose panel for selection
		var posepanel = document.getElementById("posepanel");

		for (let k in poseDict) {
			const poseName = poseDict[k]
			var tag = document.createElement("a");
            tag.className = 'folderName'
			var text = document.createTextNode(poseName);
			tag.appendChild(text);
			posepanel.appendChild(tag);
			tag.onclick = function() {
                var jsonstring;
                Object.keys(poseLib.body).forEach(x => jsonstring = poseLib.body[x].name == poseName ? poseLib.body[x].values: jsonstring);
				this.selectedCharacter.setPose(JSON.parse(jsonstring))
            }.bind(this)
		}
    }

    selectCharacter(character_to_select){
        if (this.faceGuiOn)
            {
                this.selectedCharacter.gui.hide()
                this.selectedCharacter.gui.visible = false
                character_to_select.gui.show()
                character_to_select.gui.visible = true
            }
        this.selectedCharacter = character_to_select
    }

    poseJoints(){
        this.characters.forEach(x => x.setJointsVisibility(this.jointsVisible))
        this.characters.forEach(x => x.makeJointsTransformable(!this.jointsVisible))
        this.jointsVisible = !this.jointsVisible
    }
}