export default class ImageContextMenu
{
  constructor(imgElement)
  {
    this.contextMenu = document.getElementById("captured-image-context-menu");
    this.scope = imgElement;
  }

  openContextMenu()
  {
      this.scope.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    
      const { clientX: mouseX, clientY: mouseY } = event;
    
      const { normalizedX, normalizedY } = this.normalizePosition(mouseX, mouseY);
    
      this.contextMenu.classList.remove("visible");
    
      this.contextMenu.style.top = `${normalizedY}px`;
      this.contextMenu.style.left = `${normalizedX}px`;
    
      setTimeout(() => {
        this.contextMenu.classList.add("visible");
      });
    });
  }

  closeContextMenu()
  {
    this.scope.addEventListener("click", (e) => {
      // ? close the menu if the user clicks outside of it
      if (e.target.offsetParent != this.contextMenu) {
        this.contextMenu.classList.remove("visible");
      }
    });
  }

  normalizePosition(mouseX, mouseY) {
    // ? compute what is the mouse position relative to the container element (scope)
    let {
      left: scopeOffsetX,
      top: scopeOffsetY,
    } = this.scope.getBoundingClientRect();
    
    scopeOffsetX = scopeOffsetX < 0 ? 0 : scopeOffsetX;
    scopeOffsetY = scopeOffsetY < 0 ? 0 : scopeOffsetY;
   
    const scopeX = mouseX - scopeOffsetX;
    const scopeY = mouseY - scopeOffsetY;
  
    // ? check if the element will go out of bounds
    const outOfBoundsOnX =
      scopeX + this.contextMenu.clientWidth > this.scope.clientWidth;
  
    const outOfBoundsOnY =
      scopeY + this.contextMenu.clientHeight > this.scope.clientHeight;
  
    let normalizedX = mouseX;
    let normalizedY = mouseY;
  
    // ? normalize on X
    if (outOfBoundsOnX) {
      normalizedX =
        scopeOffsetX + this.scope.clientWidth - this.contextMenu.clientWidth;
    }
  
    // ? normalize on Y
    if (outOfBoundsOnY) {
      normalizedY =
        scopeOffsetY + this.scope.clientHeight - this.contextMenu.clientHeight;
    }
  
    return { normalizedX, normalizedY };
  };  
}