import * as THREE from "three";

const vertexShader = `
#define USE_UV

varying vec3 vViewPosition;
#include <common>
#include <uv_pars_vertex>
#include <uv2_pars_vertex>
#include <displacementmap_pars_vertex>
#include <color_pars_vertex>
#include <fog_pars_vertex>
#include <normal_pars_vertex>
#include <morphtarget_pars_vertex>
#include <skinning_pars_vertex>
#include <shadowmap_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>



void main() {
	#include <uv_vertex>
	#include <uv2_vertex>
	#include <color_vertex>
	#include <morphcolor_vertex>
	#include <beginnormal_vertex>
	#include <morphnormal_vertex>
	#include <skinbase_vertex>
	#include <skinnormal_vertex>
	#include <defaultnormal_vertex>
	#include <normal_vertex>
	#include <begin_vertex>
	#include <morphtarget_vertex>
	#include <skinning_vertex>
	#include <displacementmap_vertex>
	#include <project_vertex>
	#include <logdepthbuf_vertex>
	#include <clipping_planes_vertex>
	vViewPosition = - mvPosition.xyz;
	#include <worldpos_vertex>
	#include <shadowmap_vertex>
	// #include <fog_vertex>
	vUv = uv;
}
`

const fragmentShader = `
#define USE_UV

struct ColorRampItem {
	vec3 color;
	float start;
	float end;
};
uniform ColorRampItem uColorRamp[NUM_COLORS];

float gradient(float shading){
	float nSlice =float(NUM_COLORS);
	// nSlice += 1.;
	float slice = ceil(shading * nSlice)/nSlice;
	return (slice);
}



#include <common>
#include <packing>
#include <dithering_pars_fragment>
#include <color_pars_fragment>
#include <uv_pars_fragment>
#include <uv2_pars_fragment>
#include <map_pars_fragment>
#include <alphamap_pars_fragment>
#include <alphatest_pars_fragment>
#include <aomap_pars_fragment>
#include <lightmap_pars_fragment>
#include <emissivemap_pars_fragment>
// Removed gradient map
#include <fog_pars_fragment>
#include <bsdfs>
#include <lights_pars_begin>
#include <normal_pars_fragment>




#include <shadowmap_pars_fragment>
#include <bumpmap_pars_fragment>
#include <normalmap_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>


vec3 gradientIrradiance(const in IncidentLight directLight, const in GeometricContext geometry){
	float shading =  (max(0., dot(geometry.normal, directLight.direction) )+ 0.001);
	vec3 color = uColorRamp[0].color;
	float start = 0.;
	float end = uColorRamp[0].end;
	for(int i =0; i < NUM_COLORS; i++){
		ColorRampItem item = uColorRamp[i];
		
		if(shading > end){
			float mixer = smoothstep(end, item.start, shading );
			color = mix(color, item.color, mixer);
			end = item.end;
			start = item.start;
		}
	}

	return vec3(color);
}

varying vec3 vViewPosition;
struct ToonMaterial {
	vec3 diffuseColor;
};
void RE_Direct_Toon( const in IncidentLight directLight, const in GeometricContext geometry, const in ToonMaterial material, inout ReflectedLight reflectedLight ) {
	vec3 irradiance = gradientIrradiance(directLight, geometry) * directLight.color;
	reflectedLight.directDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
}
void RE_IndirectDiffuse_Toon( const in vec3 irradiance, const in GeometricContext geometry, const in ToonMaterial material, inout ReflectedLight reflectedLight ) {
	reflectedLight.indirectDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
}
#define RE_Direct				RE_Direct_Toon
#define RE_IndirectDiffuse		RE_IndirectDiffuse_Toon



	uniform vec3 uColor;
	#ifdef USE_MAP
		uniform sampler2D uMap;
	#endif


	uniform float uUseGrayScale;
	
	void main() {
		vec2 uv= vUv;

		vec3 diffuse = uColor;
		#ifdef USE_MAP
			diffuse *= texture2D(uMap, uv).rgb;
		#endif
		float grayscale = (diffuse.r + diffuse.g + diffuse.b)/3.;
		diffuse = mix(diffuse, vec3(grayscale), uUseGrayScale);

		vec4 diffuseColor = vec4( diffuse, 1. );

		
		#include <color_fragment>
		#include <alphamap_fragment>
		#include <alphatest_fragment>
		#include <normal_fragment_begin>
		#include <normal_fragment_maps>
		#include <emissivemap_fragment>


		ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
		vec3 totalEmissiveRadiance = vec3(0.);

		ToonMaterial material;
		material.diffuseColor = diffuseColor.rgb;

		#include <lights_fragment_begin>
		#include <lights_fragment_end>

		vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;

		
		#include <output_fragment>
		// gl_FragColor = vec4(vec3(outgoingLight), 1. );
	
		#include <tonemapping_fragment>
		#include <encodings_fragment>
		#include <fog_fragment>
		#include <premultiplied_alpha_fragment>
		#include <dithering_fragment>
		// gl_FragColor = vec4(vec3(diffuse), 1. );
	}
`
const defaultColors = [
	{color: new THREE.Color('#a1a1a1'), start: 0., end: 0.499},
	{color: new THREE.Color('#ffffff'), start: 0.5, end: 1. },
]
const uUseGrayScale = new THREE.Uniform(0)

/**
 * 
 * Colors -> Array of colors in a ramp. 
 * 			 1.	Each item's "end" must end before the next item "start", 
 * 					even if it's 0.0001 
 * 			 2. They length of the array must be defined at creation.
 * 					It cannot be changed, however the values inside each
 * 					Item Can be changed.
 */
export class ToonMaterial extends THREE.ShaderMaterial {
	constructor({colors = defaultColors, defines = {}, ...opts} = {}){
		super({
			vertexShader,
			fragmentShader,
			extensions: {
				derivatives: true,
			},
			lights: true,
			uniforms: {
				uColor: new THREE.Uniform(new THREE.Color(1,1,1)),
				uUseGrayScale: uUseGrayScale,
				uColorRamp: {value: colors },
				...THREE.UniformsLib.lights,
				// uMap: new THREE.Uniform(null),
			},
			defines: {
				NUM_COLORS: colors.length,
				...defines
			},
			...opts
		})

		
	this.onBeforeCompile = function(shader){
		if(this.map){
			shader.uniforms.uMap = new THREE.Uniform(this.map);
		}
		if(this.alphaTest > 0){
			shader.uniforms.alphaTest = new THREE.Uniform(this.alphaTest);
		}
		if(this.alphaMap){
			shader.uniforms.alphaMap = new THREE.Uniform(this.alphaMap);
		}
	}
	}
	copy( source ){
		
		THREE.Material.prototype.copy.call(this, source)

		this.uniforms.uColor.value.copy(source.color);
		this.map = source.map;

		// this.emissive.copy( source.emissive );
		// this.emissiveMap = source.emissiveMap;
		// this.emissiveIntensity = source.emissiveIntensity;

		// this.normalMap = source.normalMap;
		// this.normalMapType = source.normalMapType;
		// this.normalScale.copy( source.normalScale );
		
		this.alphaMap = source.alphaMap;

		return this;
	}
}
