import * as THREE from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import Experience from '../Experience.js';

import { storage } from "./FirebaseClient.js"
import { getAuth } from "firebase/auth";
import { ref, uploadBytes, uploadBytesResumable } from "firebase/storage";

export default function exportGLTF(input, scene_name) {

    const gltfExporter = new GLTFExporter();

    const loadingBarElement = document.querySelector('.loading-bar')
    const loadingBgElement = document.querySelector('.loading-background')
    loadingBarElement.style.background = 'black'
    loadingBarElement.classList.remove('ended')
    loadingBgElement.classList.remove('ended')
    loadingBgElement.innerHTML = "Exporting your edits ... (Don't close this window)"

    const options = {
        trs: true,
        onlyVisible: true,
        binary: true,
        maxTextureSize: 4096
    };
    
    gltfExporter.parse(
        input,
        function (result) {

            if (result instanceof ArrayBuffer) {

                saveArrayBuffer(result, scene_name.concat('.glb'));

            } else {

                const output = JSON.stringify(result, null, 2);
                saveString(output, scene_name.concat('.gltf'));

            }

        },
        function (error) {

            console.log('An error happened during parsing', error);

        },
        options
    );
}

const link = document.createElement('a');
link.style.display = 'none';
document.body.appendChild(link);

// const experience = new Experience()

function save(blob, filename) {
    const loadingBarElement = document.querySelector('.loading-bar')
    const loadingBgElement = document.querySelector('.loading-background')
    loadingBarElement.style.background = 'black'
    loadingBarElement.classList.remove('ended')
    loadingBgElement.classList.remove('ended')

    const auth = getAuth();
    auth.onAuthStateChanged(function (user) {
        if (user) {
            //const storageRef = ref(storage, 'creators/test-creator/scenes/' + filename);
            const storageRef = ref(storage, 'creators/' + user.uid + '/scenes/' + filename);
            const experience = new Experience()

            const uploadTask = uploadBytesResumable(storageRef, blob);
            // uploadBytesResumable(storageRef, blob).then((snapshot) => {
            //     alert(filename.split(".")[0] + " is saved.")
            //     experience.stashManager.refreshStash()
            // });

            uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes)*0.9 + 0.1
                loadingBgElement.innerHTML = "Pushing to the KURUKURU server ... (Don't close this window) "+ Math.ceil(progress * 100)  + "%"
                loadingBarElement.style.transform = `scaleX(${progress})`
            }, 
            (error) => {
                // Handle unsuccessful uploads
            }, 
            () => {
                loadingBarElement.classList.add('ended')
                loadingBgElement.classList.add('ended')
                loadingBarElement.style.transform = ''
                loadingBarElement.style.background = 'white'
                alert(filename.split(".")[0] + " is saved.")
                experience.stashManager.refreshStash()
            });

            // link.href = URL.createObjectURL( blob );
            // link.download = filename;
            // link.click();

            // URL.revokeObjectURL( url ); breaks Firefox...
        } else {
            alert('Not signed in')
            window.location.href = '/login.html';
        }
    });

}

function saveString(text, filename) {

    save(new Blob([text], { type: 'text/plain' }), filename);

}


function saveArrayBuffer(buffer, filename) {

    save(new Blob([buffer], { type: 'application/octet-stream' }), filename);

}