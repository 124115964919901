import * as THREE from "three";
import { getFBO } from "../js/FBO";
import { aastep } from "../shaders/aastep";
let geometry = null;
export function getFullscreenTriangle() {
  if (geometry === null) {
    const vertices = new Float32Array([-1, -1, 0, 3, -1, 0, -1, 3, 0]);
    const uvs = new Float32Array([0, 0, 2, 0, 0, 2]);
    geometry = new THREE.BufferGeometry();

    // Added for backward compatibility (setAttribute was added in three r110).
    if (geometry.setAttribute !== undefined) {
      geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
      geometry.setAttribute("uv", new THREE.BufferAttribute(uvs, 2));
    } else {
      geometry.addAttribute("position", new THREE.BufferAttribute(vertices, 3));
      geometry.addAttribute("uv", new THREE.BufferAttribute(uvs, 2));
    }
  }

  return geometry;
}
/**
 * Outline size -> When changed the material needs to be created.
 * manualOverried -> Manual override enables per mesh change. However, it's a bit performance heavy
 * due to multiple traverse needed.
 * 
 * Debugging to see where the outline issue is comming from:
 * 
        debugNormalDiff: false,
        debugDepthDiff: false,
        debugColor: false,
 */
export class OutlinePostProcess {
  constructor(renderer, opts) {
    this.renderer = renderer;

    opts = Object.assign(
      {
        depthBias: 1,
        depthMultiplier: 1,
        normalBias: 1,
        normalMultiplier: 1,
        outlineSize: 1,
        outlineColor: "#000000",
        debugNormalDiff: false,
        debugDepthDiff: false,
        debugColor: false,
        manualOverride: false,
      },
      opts
    );

    this.opts = opts;

    // Size is given my render target
    this.depthTexture = new THREE.DepthTexture(
      null,
      null,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      THREE.DepthFormat
    );
    // Needs more precision for a larger camera view
    this.depthTexture.type = THREE.UnsignedIntType;
    this.colorTarget = getFBO(1, 1, { depthTexture: this.depthTexture });
    this.normalTarget = getFBO(1, 1);

    this.borderScene = new THREE.Scene();
    this.borderCamera = new THREE.Camera();
    this.uScreenSize = new THREE.Uniform(
      new THREE.Vector4(
        1,
        1,
        1,
        1
        //   this.vp.renderWidth,
        //   this.vp.renderHeight,
        //   1 / this.vp.renderWidth,
        //   1 / this.vp.renderHeight
      )
    );
    this.uMultiplierParameters = {
      value: new THREE.Vector4(
        opts.depthBias,
        opts.depthMultiplier,
        opts.normalBias,
        opts.normalMultiplier
      ),
    };
    this.fullscreenMeshBorder = new THREE.Mesh(
      getFullscreenTriangle(),
      this.getMaterial()
    );
    this.fullscreenMeshBorder.material.extensions = {
      derivatives: true, // set to use derivatives
      // fragDepth: false, // set to use fragment depth values
      // drawBuffers: false, // set to use draw buffers
      // shaderTextureLOD: false // set to use shader texture LOD
    };

    this.overrideMaterial = new THREE.MeshNormalMaterial({
      // remove side if any issue
      side: THREE.DoubleSide,
    });
	// This material display just basic colors so the normal detection doesn't catch it.
    this.invisibleMaterial = new THREE.MeshBasicMaterial({
      colorWrite: true,
      depthWrite: true,
      depthTest: true,
    });
    this.borderScene.add(this.fullscreenMeshBorder);
  }
  getMaterial() {
    let opts = this.opts;
    return new THREE.ShaderMaterial({
      extensions: {
        derivatives: true,
      },
      defines: {
        OUTLINE_SIZE: opts.outlineSize,
      },
      fragmentShader: `
			  precision highp float;
			  varying vec2 vUv;
			  uniform sampler2D uMap;
			  uniform sampler2D uDepthMap;
			  uniform sampler2D uNormalMap;
			  uniform float cameraNear;
			  uniform float cameraFar;
			  uniform vec4 uScreenSize;
			  uniform vec3 uOutlineColor;
	  
			  uniform vec4 uMultiplierParameters;
			  #include <packing>
			  float readDepth (sampler2D depthSampler, vec2 coord) {
				  float fragCoordZ = texture2D(depthSampler, coord).x;
				  float viewZ = perspectiveDepthToViewZ( fragCoordZ, cameraNear, cameraFar );
				  return viewZToOrthographicDepth( viewZ, cameraNear, cameraFar );
			  }
			  float getPixelDepth (int x, int y){
				  return readDepth(uDepthMap, vUv + uScreenSize.zw * vec2(x,y));
			  }
			  vec3 getPixelNormal (int x, int y){
				  return texture2D(uNormalMap, vUv + uScreenSize.zw * vec2(x,y)).rgb;
			  }
			//    float saturate(float num) { 
			//   	return clamp(num, 0., 1.0);
			//   }
			${aastep}
			// float aastep(float line, float value) {
			//   #ifdef GL_OES_standard_derivatives
			//     float afwidth = length(vec2(dFdx(value), dFdy(value))) * 0.70710678118654757;
			//   #else
			//     float afwidth = (1.0 / 32.0) * (1.4142135623730951 / (2.0 * gl_FragCoord.w));
			//   #endif
			//   return smoothstep(line - afwidth, line + afwidth, value);
			// }
			  void main() {
				  vec2 uv= vUv;
				  float depth = getPixelDepth(0,0);
				  float depthDiff = 0.0;
				  int size = OUTLINE_SIZE;
				  for(int iy =-size; iy <= size; iy++  ){
					  for(int ix =-size; ix <= size; ix++  ){
						  if(iy == 0 && ix == 0) continue;
					  depthDiff += abs(depth - getPixelDepth(ix, iy));
					  }
				  }
				  vec3 normal = getPixelNormal(0,0);
				  float normalDiff = 0.0;
				  
				  for(int iy =-size; iy <= size; iy++  ) {
					  for(int ix =-size; ix <= size; ix++  ) {
						  if(iy == 0 && ix == 0) continue;
					  normalDiff += distance(normal, getPixelNormal(ix, iy));
					  }
				  }
	  
				  float depthBias = uMultiplierParameters.x;
				  float depthMultiplier = uMultiplierParameters.y;
				  float normalBias = uMultiplierParameters.z;
				  float normalMultiplier = uMultiplierParameters.w;
	  
				  
				  depthDiff = depthDiff * depthMultiplier;
				  depthDiff = clamp(depthDiff, 0., 1.0);
				  depthDiff = pow(depthDiff, depthBias);
	  
				  // normalDiff = normalDiff * 8.;
				  normalDiff = normalDiff * normalMultiplier;
				  normalDiff =clamp(normalDiff, 0., 1.0);
				  normalDiff = pow(normalDiff, normalBias);
	  
				  float outline = normalDiff + depthDiff;
				  outline = aastep(0.99, outline);
	  
				  vec4 sceneColor = texture2D(uMap, vUv);
	  
				  vec4 outlineColor = vec4(uOutlineColor, 1.);
		  
				//   vec2 fw = fwidth( outline ) * 0.5;
				//   float aaOutline = smoothstep( 0.7 - fw.x, 0.7 + fw.x, outline );
				  gl_FragColor = mix(sceneColor, outlineColor, outline);
				  ${opts.debugDepthDiff ? `gl_FragColor = vec4(vec3(depthDiff),1.);` : ""}
				  ${opts.debugNormalDiff ? `gl_FragColor = vec4(vec3(normalDiff),1.);` : ""}
				  ${opts.debugNormals ? `gl_FragColor = vec4(vec3(normal),1.);` : ""}
				  ${opts.debugColor ? `gl_FragColor = sceneColor;` : ""}
				  
				//   gl_FragColor = sceneColor;
				  #include <tonemapping_fragment>
				  #include <encodings_fragment>
				//   gl_FragColor = vec4(vec3(depthDiff),1.);
				//   gl_FragColor = vec4(vec3(getPixelDepth(0,0)),1.);
				  // gl_FragColor = texture2D(uNormalMap, vUv);
			  }
			  
			  
			  `,
      vertexShader: `
				varying vec2 vUv;
		  void main() {
			  vec3 transformed = position;
			  vUv = uv;
			  gl_Position = vec4(transformed, 1.);
		  }`,
      uniforms: {
        uMap: new THREE.Uniform(this.colorTarget.texture),
        uDepthMap: new THREE.Uniform(this.depthTexture),
        uNormalMap: new THREE.Uniform(this.normalTarget.texture),
        cameraNear: { value: 0 }, //  this.camera.near
        cameraFar: { value: 0 }, // this.camera.far
        uOutlineColor: {
          value: new THREE.Color(opts.outlineColor),
        },
        uMultiplierParameters: this.uMultiplierParameters,
        uScreenSize: this.uScreenSize,
      },
    });
  }
  updateMaterial = () => {
    this.fullscreenMeshBorder.material.dispose();
    this.fullscreenMeshBorder.material = this.getMaterial();
  };

  setSize(w, h) {
    this.normalTarget.setSize(w, h);
    this.colorTarget.setSize(w, h);

    this.uScreenSize.value.set(w, h, 1 / w, 1 / h);
    // this.renderPass.setSize(w, h);
  }
  render(scene, camera) {
    let originalTarget = this.renderer.getRenderTarget();
    this.renderer.outputEncoding = THREE.LinearEncoding;
    this.renderer.toneMapping = THREE.NoToneMapping;

    this.fullscreenMeshBorder.material.uniforms.cameraNear.value = camera.near;
    this.fullscreenMeshBorder.material.uniforms.cameraFar.value = camera.far;
    this.renderer.setRenderTarget(this.colorTarget);
    this.renderer.render(scene, camera);

    if (this.opts.manualOverride) {
      // console.log(this.scene.children)
      // this.scene.loo
      scene.traverse((m) => {
        if (!m.material) return;
        m.originalMaterial = m.material;

        // Check if it has it's own overrideMaterial
        if (m.overrideMaterial) m.material = m.overrideMaterial;
        else if (m.userData.outlineNormalDisable) {
          m.material = this.invisibleMaterial;
        } else {
          m.material = this.overrideMaterial;
        }

        // Check if it has it's own render order for outline process
        if (m.overrideRenderOrder != null) {
          m.originalRenderOrder = m.renderOrder;
          m.renderOrder = m.overrideRenderOrder;
        }
      });
    } else {
      scene.overrideMaterial = this.overrideMaterial;
    }

    this.renderer.setRenderTarget(this.normalTarget);
    this.renderer.render(scene, camera);

    if (this.opts.manualOverride) {
      scene.traverse((m) => {
        if (!m.material) return;

        // if(m.userData.outlineNormalDisable){
        // 	m.visible = true;
        // }

        m.material = m.originalMaterial;
        if (m.overrideRenderOrder != null)
          m.renderOrder = m.originalRenderOrder;
      });
    } else {
      scene.overrideMaterial = null;
    }

    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;

    this.renderer.setRenderTarget(originalTarget);
    this.renderer.render(this.borderScene, this.borderCamera);
  }
}
