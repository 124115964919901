import EventEmitter from './EventEmitter.js'

export default class Sizes extends EventEmitter
{
    constructor()
    {
        super()
        this.canvas = window.experience.canvas
        // Setup
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        // this.width = this.canvas.getBoundingClientRect().width
        // this.height = this.canvas.getBoundingClientRect().height;
        // this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        // Resize event
        window.addEventListener('resize', () =>
        {
            // this.width = this.canvas.clientWidth
            // this.height = this.canvas.clientHeight
            this.width = window.innerWidth
            this.height = window.innerHeight            
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)

            this.trigger('resize')
        })
    }
}