import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        this.setInstance()
        this.setControls()
    }

    getCameraPosition(){
        return this.instance.position
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(50, this.sizes.width / this.sizes.height, 0.01, 1000)
        this.instance.position.set(-3, 1, 3)
        // this.instance.lookAt()
        this.scene.add(this.instance)
        this.debugFolder = this.experience.debug.ui.addFolder('Camera')
        this.debugFolder
        .add(this.instance, "aspect")
        .name('Aspect')
        .min(0.001)
        .max(2)
        .step(0.001).onChange(value => {
            this.instance.updateProjectionMatrix()
            this.experience.update()
        }).listen()

        this.debugFolder
        .add(this.instance, "fov")
        .name('FOV')
        .min(1)
        .max(100)
        .step(0.1).onChange(value => {
            this.instance.updateProjectionMatrix()
            this.experience.update()
    }).listen()

        this.debugFolder
        .add(this.instance, "zoom")
        .name('Zoom')
        .min(0)
        .max(10)
        .step(0.01).onChange(value => {
            this.instance.updateProjectionMatrix()
            this.experience.update()
    }).listen()

    var params = {
        ResetCamera: function() {
            this.instance.aspect = this.sizes.width / this.sizes.height
            this.instance.fov = 50
            this.instance.zoom = 1
            this.instance.updateProjectionMatrix()
            this.experience.update()
        }.bind(this),
    };

    this.debugFolder.add( params, 'ResetCamera' ); 
    }

    setControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = false
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        this.controls.update()
    }
}