const jointsPreset = [{
    name: "mixamorigHips",
    scale: 1,
    color: 0xFF00FF,
},
{
    name: "mixamorigSpine",
    scale: 1,
    color: 0x494a21,
},
{
    name: "mixamorigSpine1",
    scale: 1,
    color: 0x7b7b38,
},
{
    name: "mixamorigSpine2",
    scale: 1,
    color: 0xacac4f,
},
{
    name: "mixamorigNeck",
    scale: 1,
    color: 0xddde65,
},
{
    name: "mixamorigHead",
    scale: 1,
    color: 0xf6f771,
},
{
    name: "mixamorigLeftShoulder",
    scale: 1,
    color: 0x652b47,
},
{
    name: "mixamorigLeftArm",
    scale: 1,
    color: 0x97416b,
},
{
    name: "mixamorigLeftForeArm",
    scale: 1,
    color: 0xca578f,
},
{
    name: "mixamorigLeftHand",
    scale: 1,
    color: 0xfd6db3,
},
{
    name: "mixamorigRightShoulder",
    scale: 1,
    color: 0x415e7d,
},
{
    name: "mixamorigRightArm",
    scale: 1,
    color: 0x5b84af,
},
{
    name: "mixamorigRightForeArm",
    scale: 1,
    color: 0x75aae1,
},
{
    name: "mixamorigRightHand",
    scale: 1,
    color: 0x83bdfa,
},
{
    name: "mixamorigRightUpLeg",
    scale: 1,
    color: 0x744e99,
},
{
    name: "mixamorigRightLeg",
    scale: 1,
    color: 0x9b68cc,
},
{
    name: "mixamorigRightFoot",
    scale: 1,
    color: 0xae75e5,
},
{
    name: "mixamorigRightToeBase",
    scale: 1,
    color: 0xc282ff,
},
{
    name: "mixamorigLeftUpLeg",
    scale: 1,
    color: 0x7e3736,
},
{
    name: "mixamorigLeftLeg",
    scale: 1,
    color: 0xb14d4c,
},
{
    name: "mixamorigLeftFoot",
    scale: 1,
    color: 0xe36362,
},
{
    name: "mixamorigLeftToeBase",
    scale: 1,
    color: 0xfd6f6d,
},
{
    name: "mixamorigRightHandPinky1",
    scale: 5,
    type: "finger",
    color: 0x0000ff,
    axis: {x: true, y: true, z: true}
},
{
    name: "mixamorigRightHandPinky2",
    scale: 5,
    type: "finger",
    color: 0x0000ff,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigRightHandPinky3",
    scale: 5,
    type: "finger",
    color: 0x0000ff,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigRightHandRing1",
    scale: 5,
    type: "finger",
    color: 0x00ffff,
    axis: {x: true, y: true, z: true}
},
{
    name: "mixamorigRightHandRing2",
    scale: 5,
    type: "finger",
    color: 0x00ffff,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigRightHandRing3",
    scale: 5,
    type: "finger",
    color: 0x00ffff,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigRightHandMiddle1",
    scale: 5,
    type: "finger",
    color: 0xff0000,
    axis: {x: true, y: true, z: true}
},
{
    name: "mixamorigRightHandMiddle2",
    scale: 5,
    type: "finger",
    color: 0xff0000,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigRightHandMiddle3",
    scale: 5,
    type: "finger",
    color: 0xff0000,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigRightHandIndex1",
    scale: 5,
    type: "finger",
    color: 0xff00d0,
    axis: {x: true, y: true, z: true}
},
{
    name: "mixamorigRightHandIndex2",
    scale: 5,
    type: "finger",
    color: 0xff00d0,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigRightHandIndex3",
    scale: 5,
    type: "finger",
    color: 0xff00d0,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigRightHandThumb1",
    scale: 5,
    type: "finger",
    color: 0xffff00,
},
{
    name: "mixamorigRightHandThumb2",
    scale: 5,
    type: "finger",
    color: 0xffff00,
    axis: {x: false, y: true, z: true}
},
{
    name: "mixamorigRightHandThumb3",
    scale: 5,
    type: "finger",
    color: 0xffff00,
    axis: {x: false, y: true, z: false}
},
{
    name: "mixamorigLeftHandPinky1",
    scale: 5,
    type: "finger",
    color: 0x0000ff,
    axis: {x: true, y: true, z: true}
},
{
    name: "mixamorigLeftHandPinky2",
    scale: 5,
    type: "finger",
    color: 0x0000ff,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigLeftHandPinky3",
    scale: 5,
    type: "finger",
    color: 0x0000ff,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigLeftHandRing1",
    scale: 5,
    type: "finger",
    color: 0x00ffff,
    axis: {x: true, y: true, z: true}
},
{
    name: "mixamorigLeftHandRing2",
    scale: 5,
    type: "finger",
    color: 0x00ffff,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigLeftHandRing3",
    scale: 5,
    type: "finger",
    color: 0x00ffff,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigLeftHandMiddle1",
    scale: 5,
    type: "finger",
    color: 0xff0000,
    axis: {x: true, y: true, z: true}
},
{
    name: "mixamorigLeftHandMiddle2",
    scale: 5,
    type: "finger",
    color: 0xff0000,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigLeftHandMiddle3",
    scale: 5,
    type: "finger",
    color: 0xff0000,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigLeftHandIndex1",
    scale: 5,
    type: "finger",
    color: 0xff00d0,
    axis: {x: true, y: true, z: true}
},
{
    name: "mixamorigLeftHandIndex2",
    scale: 5,
    type: "finger",
    color: 0xff00d0,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigLeftHandIndex3",
    scale: 5,
    type: "finger",
    color: 0xff00d0,
    axis: {x: false, y: false, z: true}
},
{
    name: "mixamorigLeftHandThumb1",
    scale: 5,
    type: "finger",
    color: 0xffff00,
},
{
    name: "mixamorigLeftHandThumb2",
    scale: 5,
    type: "finger",
    color: 0xffff00,
    axis: {x: false, y: true, z: true}
},
{
    name: "mixamorigLeftHandThumb3",
    scale: 5,
    type: "finger",
    color: 0xffff00,
    axis: {x: false, y: true, z: false}
}];

export default jointsPreset;