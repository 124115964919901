import Experience from "../Experience.js";

import { storage, firestore, auth } from "./FirebaseClient.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ref, getDownloadURL, deleteObject, uploadBytes, uploadString } from "firebase/storage";
import { OneMinusDstAlphaFactor } from "three";
import { updateDoc, arrayUnion, getDoc, doc, addDoc, collection } from "firebase/firestore";

const modes = ["translate", "rotate", "scale"];

const API_KEY = `e839b068-c097-4fe9-b55e-de2ea9551567`;
const MODEL_KEY = `7d27654c-f6ae-480d-a854-c5da056e0662`;
export default class ButtonManager {
    constructor() {
        this.experience = new Experience();
        this.transform = this.experience.transform;

        this.experience.world.on("ready", () => {
            this.environment = this.experience.world.environment;
            this.character = this.experience.world.char;
            this.lightButton();
            this.undoButton();
        });
        this.buttonpanel = document.getElementById("button-panel");
        this.modeNum = 1; //3 modes of transform

        this.comicPanelOpen = false;
        this.drawRectangleButtonClicked = false;
        this.uploadFolderList = [];

        this.sceneList = document.createElement("div");
        document.getElementById("scenespanel").appendChild(this.sceneList);
        // // this.sceneList.hidden = true

        this.modelList = document.createElement("div");
        document.getElementById("addPanel").appendChild(this.modelList);
        this.modelList.hidden = false;

        this.characterList = document.createElement("div");
        document.getElementById("addPanel").appendChild(this.characterList);
        this.characterList.hidden = true;

        this.generatedImages = [];

        // right button panel
        this.selectionButton();
        this.copyPasteButton();
        this.rotateButton();
        this.deleteObjectButton();
        this.saveSceneButton();
        this.collapseButton();

        //left button panel
        this.addButton();
        this.filterButton();
        this.comicPanelButton();
        this.poseButton();
        this.lightButton();
        this.faceButton();
        this.scenesButton();

        this.keyboardShortcuts();
        this.generateImageButton();
    }

    keyboardShortcuts() {
        // keyboard shortcut
        document.addEventListener(
            "keydown",
            (event) => {
                // event.preventDefault()
                var code = event.code;
                if (code == "Backspace") {
                    this.experience.transform.deleteSelectedObject();
                } else if (code == "Digit1") {
                    // key = 1, toggle transform/rotate/scale mode
                    this.transform.transformControl.setMode(modes[this.modeNum % 3]);
                    this.modeNum = this.modeNum + 1;
                } else if (code == "Digit2") {
                    // key = 2, toggle character posing mode
                    if (
                        !this.experience.shading.shadingActive &&
                        !this.experience.shading.ballpenshadingActive &&
                        !this.experience.shading.bwmangashadingActive
                    ) {
                        this.experience.poseManager.poseJoints();
                    }
                }
                // key = 3, toggle selection mode
                else if (code == "Digit3") {
                    this.selectionBtn.click();
                }
            },
            false
        );
    }

    generateImageButton() {
        this.requestInput = document.getElementById("picture-manager-input");
        this.requestNegativeInput = document.getElementById("picture-manager-negative-input");
        this.imageData = document.getElementById("generate-source-preview");
        this.requestOutput = document.getElementById("picture-manager-result-container");
        this.generateImageButton = document.getElementById("picture-manager-generate");
        
        this.generateImageButton.onclick = async function () {
            this.generateImageButton.disabled = true;
            this.generateImageButton.innerText = "Uploading";
            try {
                const timeStamp = new Date().getTime();

                const imageName = `userGenerated/${auth.currentUser.uid}/${timeStamp}.png`;
    
                const imageRef = ref(storage, imageName);

                await uploadString(
                    imageRef,
                    this.imageData.src.substring(this.imageData.src.indexOf(",") + 1),
                    "base64"
                );

                const imageDownloadURL = await getDownloadURL(imageRef);
    
                const colRef = collection(firestore, `userGen`);
    
                await addDoc(colRef, {
                    timeLoc: new Date(), 
                    time: timeStamp,
                    url: imageDownloadURL,
                    email: email,
                    uid: uid,
                });

            } catch(err) {
                alert('Something went wrong! please try again later')
                console.log(JSON.stringify(err))
            } finally {
                this.generateImageButton.disabled = false;
                this.generateImageButton.innerText = "Upload";
            }
        }.bind(this);
    }

    selectionButton() {
        this.selectionBtn = document.getElementById("selection-button");
        this.selectionBtn.style.background = "#808080";
        this.selectionBtn.onclick = function () {
            this.transform.selectionMode = !this.transform.selectionMode;
            if (this.transform.selectionMode) {
                this.selectionBtn.style.background = "#808080";
            } else {
                this.transform.deactivate();
                this.selectionBtn.style.background = "#000000";
            }
        }.bind(this);
    }

    copyPasteButton() {
        this.copyPasteBtn = document.getElementById("copy-paste-button");
        this.copyPasteBtn.onclick = function () {
            this.transform.copySelectedObject();
            this.transform.paste();
        }.bind(this);
    }

    undoButton() {
        this.undoBtn = document.getElementById("undo-button");
        this.undoBtn.onclick = function () {
            this.experience.commands.undoManager.undo();
        }.bind(this);
    }

    deleteObjectButton() {
        this.deleteObjButton = document.getElementById("delete-object-button");
        this.deleteObjButton.onclick = function () {
            this.experience.transform.deleteSelectedObject();
        }.bind(this);
    }

    saveSceneButton() {
        this.saveSceneButton = document.getElementById("save-scene-button");
        this.saveSceneButton.onclick = function () {
            this.experience.saveScene();
        }.bind(this);
    }

    addCharacters() {
        for (const [key, char] of Object.entries(
            this.experience.stashManager.stash.assets.characters
        )) {
            if (char.type == "glbModel" || char.type == "gltfModel") {
                const tag = document.createElement("a");
                tag.className = "folderName";
                const text = document.createTextNode(char.name);
                tag.appendChild(text);
                this.characterList.appendChild(tag);
                const loadingText = document.createElement("l");
                loadingText.innerText = "";
                tag.appendChild(loadingText);

                tag.onclick = function () {
                    if (!this.transform.selectionMode) {
                        this.selectionBtn.click();
                    }
                    loadingText.innerText = "Loading ...";

                    getDownloadURL(ref(storage, char.gcs_path)).then((url) => {
                        // console.log(url)
                        this.experience.world.addCharacterToScene(
                            12,
                            char.name,
                            url,
                            char.scale,
                            char.jointScale,
                            char.defaultPose
                        );
                        this.experience.world.on(char.name + " ready", () => {
                            loadingText.innerText = "";
                        });
                    });
                }.bind(this);
            }
        }
    }

    addScenes() {
        // clear scene list
        while (this.sceneList.firstChild) {
            this.sceneList.removeChild(this.sceneList.firstChild);
        }

        // add buttons
        this.newSceneButton = document.createElement("button");
        this.newSceneButton.className = "listbtn";
        // this.newSceneButton.className = "functionalbtn"
        this.newSceneButton.innerHTML = "New Scene";
        this.newSceneButton.onclick = function () {
            this.experience.newScene();
        }.bind(this);
        this.sceneList.appendChild(this.newSceneButton);

        this.renameSceneButton = document.createElement("button");
        this.renameSceneButton.className = "listbtn";
        // this.renameSceneButton.className = "functionalbtn"
        this.renameSceneButton.innerHTML = "Rename Current Scene";
        this.renameSceneButton.onclick = function () {
            let newname = prompt("Rename scene:", this.experience.scene_name);
            let allSceneNames = this.experience.stashManager.stash.assets.scenes.map((a) => a.name);

            if (allSceneNames.includes(newname)) {
                alert("Scene name " + newname + " already exists.");
                return;
            }

            if (newname == null || !newname.replace(/\s/g, "").length) {
                alert("Can't use blank as scene name.");
                return;
            }

            const curr_name = this.experience.scene_name;
            let found_scene = this.experience.stashManager.stash.assets.scenes.filter(function (
                sd
            ) {
                return sd.name == curr_name;
            });

            this.experience.setSceneName(newname);
            this.experience.saveScene();

            // const regex = new RegExp('\/(?:.(?!\/))+$');
            // const newpath = model.gcs_path.replace(regex,'/'+ newname+'.glb')
            // console.log(newpath)

            // noew delete original scene from cloud
            if (found_scene.length) {
                const desertRef = ref(storage, found_scene[0].gcs_path);
                deleteObject(desertRef).then(() => {
                    this.experience.stashManager.refreshStash();
                });
            }
        }.bind(this);
        this.sceneList.appendChild(this.renameSceneButton);

        this.newSceneButton = document.createElement("button");
        this.newSceneButton.className = "listbtn listbtn--red";
        this.newSceneButton.innerHTML = "Delete Current Scene";
        this.newSceneButton.onclick = function () {
            const response = confirm(
                "Delete the current scene, " +
                    this.experience.scene_name +
                    "? This will clear everything here and this scene saved on the cloud."
            );

            if (response) {
                this.experience.destroy();
                const curr_name = this.experience.scene_name;
                let found_scene = this.experience.stashManager.stash.assets.scenes.filter(function (
                    sd
                ) {
                    return sd.name == curr_name;
                });
                if (found_scene.length) {
                    const desertRef = ref(storage, found_scene[0].gcs_path);
                    deleteObject(desertRef).then(() => {
                        this.experience.stashManager.refreshStash();
                        this.experience.newSceneName();
                    });
                }
            } else {
                // Do Nothing
            }
        }.bind(this);
        this.sceneList.appendChild(this.newSceneButton);

        for (const [key, model] of Object.entries(
            this.experience.stashManager.stash.assets.scenes
        )) {
            if (model.type == "gltfModel" || model.type == "glbModel") {
                const tag = document.createElement("a");
                tag.className = "folderName";
                const text = document.createTextNode(model.name);
                tag.appendChild(text);
                this.sceneList.appendChild(tag);
                // addpanel.insertBefore(addpanel.children[0], tag);

                tag.onclick = function () {
                    if (!this.transform.selectionMode) {
                        this.selectionBtn.click();
                    }
                    getDownloadURL(ref(storage, model.gcs_path))
                        .then((url) => {
                            this.experience.loadScene(url, model);
                        })
                        .catch((error) => {
                            // Handle any errors
                        });
                }.bind(this);
            }
        }
    }

    addModelFolders() {
        this.folderDict = {};
        this.uploadFolderList = [];
        this.folderList = document.createElement("div");
        this.folderList.id = "add-model-folder-list";
        document.getElementById("addPanel").appendChild(this.folderList);
        this.folderList.hidden = false;

        var folderName;
        var isUserUpload = false;

        //get folders
        this.experience.stashManager.stash.assets.models.forEach((thing) => {
            var split = thing.gcs_path.split("/");
            if (split.length < 2) {
            }
            if (split[0] === "creators") {
                folderName = split[3];
                // console.log(folderName)
                isUserUpload = true;
            } else {
                folderName = split[1];
            }

            // if folder exists, add this model to existing folder
            if (folderName in this.folderDict) {
                this.addModel(thing, this.folderDict[folderName]);
            } else {
                // else create new folder and attach to folder list
                const tag = document.createElement("a");
                const text = document.createTextNode(folderName);
                tag.className = "folderName";
                if (isUserUpload) {
                    tag.id = "folder-name-" + folderName;
                }
                tag.appendChild(text);
                this.folderList.appendChild(tag);
                // create div for folder
                const folderDiv = document.createElement("div");
                folderDiv.hidden = true;
                if (isUserUpload) {
                    folderDiv.id = "folder-" + folderName;
                    this.uploadFolderList.push(folderName);
                }
                document.getElementById("addPanel").appendChild(folderDiv);
                this.folderDict[folderName] = folderDiv;
                this.addModel(thing, folderDiv);
            }
        });

        for (var i = 0; i < this.folderList.children.length; i++) {
            const folderTag = this.folderList.children[i];
            //   console.log(foldserTag.textContent)

            folderTag.onclick = function () {
                this.folderList.hidden = true;
                // this.sceneList.hidden = true
                this.characterList.hidden = true;
                for (let key in this.folderDict) {
                    this.folderDict[key].hidden = true;
                }
                this.folderDict[folderTag.textContent].hidden = false;
            }.bind(this);
        }
        //(document.getElementById("addPanel"))
    }

    addModel(model, folder) {
        if (model.type == "gltfModel" || model.type == "glbModel") {
            const tag = document.createElement("a");
            const text = document.createElement("p");
            text.innerText = model.name;
            tag.className = "propNode";
            const img = document.createElement("img");
            img.className = "propImg";
            img.loading = "lazy";
            var loadingText = document.createElement("l");
            loadingText.innerText = "";

            getDownloadURL(ref(storage, model.thumbnail_gcs_path))
                .then((url) => {
                    img.setAttribute("src", url);
                    tag.appendChild(img);
                    tag.appendChild(text);
                    tag.appendChild(loadingText);
                })
                .catch((error) => {
                    console.log(error);
                });
            folder.appendChild(tag);
            folder.className = "modelFolders";

            tag.onclick = function () {
                if (!this.transform.selectionMode) {
                    this.selectionBtn.click();
                }
                loadingText.innerText = "Loading...";
                img.style.opacity = 0.2;
                // prevent double clicking
                tag.style.pointerEvents = "none";

                getDownloadURL(ref(storage, model.gcs_path))
                    .then((url) => {
                        this.experience.world.addModelToScene(
                            model.name,
                            url,
                            model.position,
                            model.scale,
                            model.rotation,
                            model.background,
                            false,
                            model.texture_gcs_folder
                        );
                        this.experience.world.on(model.name + " ready", () => {
                            img.style.opacity = 1;
                            loadingText.innerText = "";
                            tag.style.pointerEvents = "auto";
                        });
                    })
                    .catch((error) => {
                        img.style.opacity = 1;
                        loadingText.innerText = "";
                        tag.style.pointerEvents = "auto";
                        // Handle any errors
                    });
            }.bind(this);
        }

        if (model.type == "planeTexture") {
            const tag = document.createElement("a");
            const text = document.createElement("p");
            text.innerText = model.name;
            tag.className = "propNode";
            const img = document.createElement("img");
            img.className = "propImg";
            img.loading = "lazy";
            var loadingText = document.createElement("l");
            loadingText.innerText = "";

            getDownloadURL(ref(storage, model.thumbnail_gcs_path)).then((url) => {
                img.setAttribute("src", url);
                tag.appendChild(img);
                tag.appendChild(text);
                tag.appendChild(loadingText);
            });
            folder.appendChild(tag);
            folder.className = "modelFolders";
            tag.onclick = function () {
                loadingText.innerText = "";
                img.style.opacity = 0.2;
                // prevent double clicking
                tag.style.pointerEvents = "none";

                if (this.experience.renderer.shadingStyle != "none") {
                    alert("Can't add props when filters are on.");
                    return;
                }
                if (!this.transform.selectionMode) {
                    this.selectionBtn.click();
                }
                getDownloadURL(ref(storage, model.gcs_path))
                    .then((url) => {
                        this.experience.world.addWallToScene(
                            model.name,
                            url,
                            model.position,
                            model.scale,
                            model.rotation,
                            model.background,
                            false
                        );
                        this.experience.world.on(model.name + " ready", () => {
                            img.style.opacity = 1;
                            loadingText.innerText = "";
                            tag.style.pointerEvents = "auto";
                        });
                    })
                    .catch((error) => {
                        img.style.opacity = 1;
                        loadingText.innerText = "";
                        tag.style.pointerEvents = "auto";
                        // Handle any errors
                    });
            }.bind(this);
        }

        if (model.type == "skybox") {
            const tag = document.createElement("a");
            const text = document.createElement("p");
            text.innerText = model.name;
            tag.className = "propNode";
            const img = document.createElement("img");
            img.className = "propImg";
            img.loading = "lazy";

            getDownloadURL(ref(storage, model.thumbnail_gcs_path)).then((url) => {
                img.setAttribute("src", url);
                tag.appendChild(img);
                tag.appendChild(text);
            });
            folder.appendChild(tag);
            folder.className = "modelFolders";
            tag.onclick = function () {
                getDownloadURL(ref(storage, model.gcs_path)).then((url) => {
                    this.experience.world.environment.setSkybox(url);
                });
            }.bind(this);
        }

        if (model.type == "water") {
            const tag = document.createElement("a");
            const text = document.createElement("p");
            text.innerText = model.name;
            tag.className = "propNode";
            const img = document.createElement("img");
            img.className = "propImg";
            img.loading = "lazy";
            var loadingText = document.createElement("l");
            loadingText.innerText = "";

            getDownloadURL(ref(storage, model.thumbnail_gcs_path)).then((url) => {
                img.setAttribute("src", url);
                tag.appendChild(img);
                tag.appendChild(text);
                tag.appendChild(loadingText);
            });
            folder.appendChild(tag);
            folder.className = "modelFolders";
            tag.onclick = function () {
                tag.style.pointerEvents = "none";
                loadingText.innerText = "Loading...";
                img.style.opacity = 0.2;

                if (!this.transform.selectionMode) {
                    this.selectionBtn.click();
                }
                getDownloadURL(ref(storage, model.gcs_path))
                    .then((url) => {
                        this.experience.world.buildWater(url);
                        this.experience.world.on("water ready", () => {
                            loadingText.innerText = "";
                            img.style.opacity = 1;
                            tag.style.pointerEvents = "auto";
                        });
                    })
                    .catch((error) => {
                        img.style.opacity = 1;
                        loadingText.innerText = "";
                        tag.style.pointerEvents = "auto";
                        // Handle any errors
                    });
            }.bind(this);
        }
    }

    deleteUploadFolders() {
        // Delete the folders of user uploads from UI

        for (var i = 0; i < this.uploadFolderList.length; i++) {
            if (this.folderDict[this.uploadFolderList[i]]) {
                while (this.folderDict[this.uploadFolderList[i]].firstChild) {
                    this.folderDict[this.uploadFolderList[i]].removeChild(
                        this.folderDict[this.uploadFolderList[i]].lastChild
                    );
                }
            }
        }
    }

    reloadUploadFolders() {
        this.deleteUploadFolders();

        var folderName;

        //get folders
        this.experience.stashManager.stash.assets.models.forEach((thing) => {
            var split = thing.gcs_path.split("/");
            if (split.length < 2) {
            }
            if (split[0] === "creators") {
                folderName = split[3];
            } else {
                return;
            }
            // if folder exists, add this model to existing folder
            if (folderName in this.folderDict) {
                this.addModel(thing, this.folderDict[folderName]);
            } else {
                // else create new folder and attach to folder list
                this.uploadFolderList.push(folderName);
                const tag = document.createElement("a");
                const text = document.createTextNode(folderName);
                tag.className = "folderName";
                tag.id = "folder-name-" + folderName;

                tag.appendChild(text);
                // document.getElementById('add-model-folder-list').appendChild(tag);
                document
                    .getElementById("add-model-folder-list")
                    .insertBefore(tag, document.getElementById("add-model-folder-list").firstChild);
                // create div for folder
                const folderDiv = document.createElement("div");
                folderDiv.hidden = true;
                folderDiv.id = "folder-" + folderName;
                document.getElementById("addPanel").appendChild(folderDiv);
                this.folderDict[folderName] = folderDiv;
                this.addModel(thing, folderDiv);
            }
        });

        for (var i = 0; i < this.folderList.children.length; i++) {
            const folderTag = this.folderList.children[i];

            folderTag.onclick = function () {
                this.folderList.hidden = true;
                this.characterList.hidden = true;
                for (let key in this.folderDict) {
                    this.folderDict[key].hidden = true;
                }
                this.folderDict[folderTag.textContent].hidden = false;
            }.bind(this);
        }
    }

    refreshUploadMenu() {
        const auth = getAuth();
        auth.onAuthStateChanged(
            function (user) {
                if (user) {
                    //refresh stash and menu after user upload props
                    this.experience.stashManager.refreshUploads(user.uid);
                    // Async functions
                    // Only run addModelFolders after refreshUploads is finished
                    // refreshUploads has two tasks: refresh props and refresh images
                    // when refreshUploads is completed, it could end up with either situation as below
                } else {
                    alert("Not signed in");
                    window.location.href = "/login.html";
                }
            }.bind(this)
        );
    }

    addButton() {
        let addButton = document.getElementById("add-button");

        addButton.onclick = (event) => {
            // this.openSidePanel()
            this.togglePanel(1);
            // console.log("Scene polycount:", this.experience.renderer.instance.info.render.triangles)
            // console.log("Active Drawcalls:", this.experience.renderer.instance.info.render.calls)
            // console.log("Textures in Memory", this.experience.renderer.instance.info.memory.textures)
            // console.log("Geometries in Memory", this.experience.renderer.instance.info.memory.geometries)
        };

        // let closeSidePanelButton = document.getElementById("addpanelclosebutton")
        // closeSidePanelButton.onclick = (event) => {
        //     // this.closeSidePanel()
        //     this.togglePanel(null)
        // }

        // let sceneListBtn = document.getElementById("sceneList")
        // sceneListBtn.onclick = (event) => {
        //     this.characterList.hidden = true
        //     this.folderList.hidden = true
        //     for (let key in this.folderDict) {
        //         this.folderDict[key].hidden = true
        //     }
        //     this.sceneList.hidden = false
        // }

        let folderListBtn = document.getElementById("modelList");
        folderListBtn.onclick = (event) => {
            // this.sceneList.hidden = true
            this.characterList.hidden = true;
            for (let key in this.folderDict) {
                this.folderDict[key].hidden = true;
            }
            this.folderList.hidden = false;
        };

        let characterListBtn = document.getElementById("characterList");
        characterListBtn.onclick = (event) => {
            // this.sceneList.hidden = true
            this.folderList.hidden = true;
            for (let key in this.folderDict) {
                this.folderDict[key].hidden = true;
            }
            this.characterList.hidden = false;
        };
    }

    faceButton() {
        let faceButton = document.getElementById("face-button");

        faceButton.onclick = (event) => {
            const face_gui = this.experience.poseManager.selectedCharacter.gui;
            if (this.experience.poseManager.faceGuiOn) {
                this.experience.poseManager.faceGuiOn = false;
                face_gui.hide();
                face_gui.visible = false;
            } else {
                this.experience.poseManager.faceGuiOn = true;
                face_gui.show();
                face_gui.visible = true;
            }
        };
    }

    scenesButton() {
        let scenesButton = document.getElementById("scenes-button");

        scenesButton.onclick = (event) => {
            this.togglePanel(5);
            // document.getElementById("lightpanel").style.width = "300px";
            // this.buttonpanel.style.left = "-40px"
        };
    }

    lightButton() {
        let lightButton = document.getElementById("light-button");

        lightButton.onclick = (event) => {
            this.togglePanel(4);
            // document.getElementById("lightpanel").style.width = "300px";
            // this.buttonpanel.style.left = "-40px"
        };

        // let lightPanelButton = document.getElementById("lightpanelclosebutton")
        // lightPanelButton.onclick = (event) => {
        //     this.togglePanel(null)
        //     // document.getElementById("lightpanel").style.width = "0";
        //     // this.buttonpanel.style.left = "20px"
        //     this.experience.debug.ui.hide()
        //     this.experience.debug.ui.visible = false
        // }

        let customButton = document.getElementById("lightpanelcustombutton");
        customButton.className = "listbtn";
        customButton.onclick = (event) => {
            if (!this.experience.debug.ui.visible) {
                this.experience.debug.ui.show();
                this.experience.debug.ui.visible = true;
            } else {
                this.experience.debug.ui.hide();
                this.experience.debug.ui.visible = false;
            }
        };

        // let lightIntensitySlider = document.getElementById('lightIntensity')
        // lightIntensitySlider.oninput = function(value) {
        //      this.environment.sunLight.intensity = value;
        //   }
    }

    filterButton() {
        let lightButton = document.getElementById("filter-button");

        lightButton.onclick = (event) => {
            this.togglePanel(3);
            // document.getElementById("filterpanel").style.width = "300px";
            // this.buttonpanel.style.left = "-40px"
        };

        // let lightPanelButton = document.getElementById("filterpanelclosebutton")
        // lightPanelButton.onclick = (event) => {
        //     // document.getElementById("filterpanel").style.width = "0";
        //     this.togglePanel(null)
        //     // this.buttonpanel.style.left = "20px"
        // }

        let animeButton = document.getElementById("classicanimefilter");
        animeButton.onclick = (event) => {
            this.transform.deactivate();
            if (this.experience.shading.shadingActive) {
                this.experience.shading.resetShading();
            } else {
                this.experience.shading.resetShading();
                this.experience.shading.simpleToonShading().then(() => {});
                this.experience.shading.shadingActive = true;
            }
        };

        let bpshadingButton = document.getElementById("ballpensketchfilter");
        bpshadingButton.onclick = (event) => {
            this.transform.deactivate();
            if (this.experience.shading.ballpenshadingActive) {
                this.experience.shading.resetShading();
            } else {
                this.experience.shading.resetShading();
                this.experience.shading.handSketchShading();
                this.experience.shading.ballpenshadingActive = true;
            }
        };

        let cyberpunkButton = document.getElementById("cyberpunkfilter");
        cyberpunkButton.onclick = (event) => {
            this.transform.deactivate();
            if (this.experience.shading.cyberpunkActive) {
                this.experience.shading.resetShading();
            } else {
                this.experience.shading.resetShading();
                this.experience.shading.twoSidedToonShading();
                this.experience.shading.cyberpunkActive = true;
            }
        };

        let bwmangaButton = document.getElementById("bwmangafilter");
        bwmangaButton.onclick = (event) => {
            this.transform.deactivate();
            if (this.experience.shading.bwmangashadingActive) {
                this.experience.shading.resetShading();
            } else {
                this.experience.shading.resetShading();
                this.experience.shading.bwMangaShading();
                this.experience.shading.bwmangashadingActive = true;
            }
        };
    }

    comicPanelButton() {
        let comicButton = document.getElementById("comic-editor-button");

        comicButton.onclick = (event) => {
            if (this.comicPanelOpen) {
                this.closeComicPanel();
            } else {
                this.experience.debug.ui.hide();
                this.experience.debug.ui.visible = false;
                this.openComicPanel();
            }
        };

        let closeComicPanelButton = document.getElementById("comicpanelclosebutton");

        closeComicPanelButton.onclick = (event) => {
            this.closeComicPanel();
            this.experience.panelDrawer.disableDrawRectangle();
        };

        let rectButton = document.getElementById("picture-manager-toggleSelect");

        rectButton.onclick = (event) => {
            if (this.comicPanelOpen) {
                if (!this.drawRectangleButtonClicked) {
                    rectButton.textContent = "Disable Select";
                    rectButton.classList.add("listbtn--red");
                    this.experience.panelDrawer.enableDrawRectangle();
                    this.drawRectangleButtonClicked = true;
                    console.log("draw enabled");
                } else {
                    rectButton.textContent = "Enable Select";
                    rectButton.classList.remove("listbtn--red");
                    this.experience.panelDrawer.disableDrawRectangle();
                    this.drawRectangleButtonClicked = false;
                    console.log("draw disabled");
                }
            }
        };
    }

    rotateButton() {
        let rotateButton = document.getElementById("rotate-button");

        rotateButton.onclick = (event) => {
            this.transform.transformControl.setMode(modes[this.modeNum % 3]);
            this.modeNum = this.modeNum + 1;
        };
    }

    poseButton() {
        let poseButton = document.getElementById("pose-button");

        poseButton.onclick = (event) => {
            this.togglePanel(2);
            // this.openPosePanel()
        };

        // let closePosePanelButton = document.getElementById("posepanelclosebutton")
        // closePosePanelButton.onclick = (event) => {
        //     this.togglePanel(null);
        //     // this.closePosePanel()
        // }

        let poseJointsButton = document.getElementById("posepaneljointsbutton");
        poseJointsButton.className = "listbtn";
        poseJointsButton.onclick = (event) => {
            if (
                !this.experience.shading.shadingActive &&
                !this.experience.shading.ballpenshadingActive &&
                !this.experience.shading.bwmangashadingActive
            ) {
                this.experience.poseManager.poseJoints();
            } else {
                alert("Please turn off filters to edit scene.");
            }
        };
    }

    collapseButton() {
        const collapseButton = document.getElementById("collapsepanel-button");
        collapseButton.onclick = () => {
            this.panelsOpen = false;
            this.togglePanel(null);
        };
    }

    openSidePanel() {
        document.getElementById("addPanel").style.width = "300px";
        // this.buttonpanel.style.left = "-40px"
    }

    /* Set the width of the sidebar to 0 (hide it) */
    closeSidePanel() {
        document.getElementById("addPanel").style.width = "0";
        // this.buttonpanel.style.left = "20px"
    }

    openPosePanel() {
        document.getElementById("posepanel").style.width = "300px";
        // this.buttonpanel.style.left = "-40px"
    }

    /* Set the width of the sidebar to 0 (hide it) */
    closePosePanel() {
        document.getElementById("posepanel").style.width = "0";
        // this.buttonpanel.style.left = "20px"
    }
    openComicPanel() {
        document.getElementById("right-button-panel").style.transform = "translateX(-15vw)";
        document.getElementById("picture-manager").style.transform = "translateX(-15vw)";
        this.comicPanelOpen = true;
    }

    closeComicPanel() {
        document.getElementById("right-button-panel").style.transform = "translateX(0)";
        document.getElementById("picture-manager").style.transform = "translateX(0)";
        this.comicPanelOpen = false;
    }

    panelsOpen = true;

    /**
     *
     * @param {1} id 1 addPanel, 2 posepanel, 3 filterpanel, 4 lightpanel, null: close all
     */
    togglePanel(id) {
        const addPanel = document.getElementById("addPanel");
        const posePanel = document.getElementById("posepanel");
        const filterPanel = document.getElementById("filterpanel");
        const lightPanel = document.getElementById("lightpanel");
        const scenePanel = document.getElementById("scenespanel");

        if (this.panelsOpen === false) {
            addPanel.style.transition = "0.2s width";
            posePanel.style.transition = "0.2s width";
            filterPanel.style.transition = "0.2s width";
            lightPanel.style.transition = "0.2s width";
            scenePanel.style.transition = "0.2s width";
        } else {
            addPanel.style.transition = "0s width";
            posePanel.style.transition = "0s width";
            filterPanel.style.transition = "0s width";
            lightPanel.style.transition = "0s width";
            scenePanel.style.transition = "0s width";
        }

        switch (id) {
            case 1:
                this.panelsOpen = true;
                addPanel.style.width = "300px";
                posePanel.style.width = "0";
                filterPanel.style.width = "0";
                lightPanel.style.width = "0";
                scenePanel.style.width = "0";
                break;
            case 2:
                this.panelsOpen = true;
                addPanel.style.width = "0";
                posePanel.style.width = "300px";
                filterPanel.style.width = "0";
                lightPanel.style.width = "0";
                scenePanel.style.width = "0";
                break;
            case 3:
                this.panelsOpen = true;
                addPanel.style.width = "0";
                posePanel.style.width = "0";
                filterPanel.style.width = "300px";
                lightPanel.style.width = "0";
                scenePanel.style.width = "0";
                break;
            case 4:
                this.panelsOpen = true;
                addPanel.style.width = "0";
                posePanel.style.width = "0";
                filterPanel.style.width = "0";
                lightPanel.style.width = "300px";
                scenePanel.style.width = "0";
                break;
            case 5:
                this.panelsOpen = true;
                addPanel.style.width = "0";
                posePanel.style.width = "0";
                filterPanel.style.width = "0";
                lightPanel.style.width = "0";
                scenePanel.style.width = "300px";
                break;
            case null:
                addPanel.style.width = "0";
                posePanel.style.width = "0";
                filterPanel.style.width = "0";
                lightPanel.style.width = "0";
                scenePanel.style.width = "0";
                break;
            default:
                break;
        }
    }
}