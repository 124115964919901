import * as THREE from 'three'

import Debug from './Utils/Debug.js'
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'
import Resources from './Utils/Resources.js'
import TransformManager from './Utils/TransformManager.js'
import Shading from './Utils/Shading.js'
import sources from './Constants/sources.js'
import ButtonManager from './Utils/Buttons.js'
import PanelDrawer from './Utils/PanelDrawer.js'
import RenderManager from './RenderManager.js'
import PoseManager from './Utils/PoseManager.js'
import exportGLTF from './Utils/ExportManager.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

let instance = null

import Stats from 'stats.js'
import GPUPickingManager from './Utils/GPUpicking.js'
import StashManager from './Utils/StashManager.js'
import Commands from './Utils/Commands.js'

const stats = new Stats()

function getDateString(){
    let current = new Date();
    let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
    let dateTime = cDate + ' ' + cTime;
    return dateTime
}

export default class Experience
{
    constructor(_canvas)
    {
        // Singleton
        if(instance)
        {
            return instance
        }
        instance = this
        
        // Global access
        window.experience = this

        // Options
        this.canvas = _canvas

        // Setup
        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.scene_name = "Scene " + getDateString()
        // this.scene.background = new THREE.Color('white')
        this.resources = new Resources(sources)
        this.camera = new Camera()
        this.renderer = new Renderer()
        this.transform = new TransformManager()
        this.shading = new Shading()
        this.world = new World()
        this.panelDrawer = new PanelDrawer()
        this.rendermanager = new RenderManager()
        this.poseManager = new PoseManager()
        this.gltfLoader = new GLTFLoader()
        this.stashManager = new StashManager()
        this.buttons = new ButtonManager()
        this.commands = new Commands()

        // this.GPUpicker = new GPUPickingManager() // need to figure out a better way to select character

        // Resize event
        this.sizes.on('resize', () =>
        {
            this.resize()
            this.update()
        })

        // Time tick event
        this.time.on('tick', () =>
        {
            stats.begin()
            stats.end()
            // this.update()
        })

        this.update()
        this.resources.on('ready', () =>
        {
            this.update()
        })
        this.world.on('ready', () =>
        {
            // this.world.char.on('ready', () =>
            // {
                this.update()
            // })
        })
		const  add = (geo) =>{

			let sphereMat = new THREE.MeshStandardMaterial({ color: 0xff0000});
			sphereMat = new THREE.MeshStandardMaterial({ color: 0xffffff});
			let sphereMesh = new THREE.Mesh(geo, sphereMat);
			this.world.scene.add(sphereMesh)
			this.world.transformableObjectArray.push(sphereMesh)
			this.world.shadeableObjectArray.push(sphereMesh)
			this.transform.select(sphereMesh)
		}
		// let sphereGeo = new THREE.SphereBufferGeometry();
		// add(sphereGeo)
		
		// let planeGeometry = new THREE.PlaneBufferGeometry(10,10);
		// add(planeGeometry)
		// // this.shading.bwMangaShading()
		// this.shading.simpleToonShading()
    }

    newSceneName(){
        this.scene_name = "Scene " + getDateString()
    }

    setSceneName(text){
        this.scene_name = text
    }

    newScene(){
        const response = confirm("Save the current scene?");

        if (response) {
            this.saveScene()
        } else {
            // Do Nothing
        }

        this.destroy()
        this.scene_name = 'Scene ' + getDateString()
        // this.saveScene()
    }

    loadScene(scene_url, scene_dict){
        const response = confirm("Save the current scene?");

        if (response) {
            this.saveScene()
        } else {
            // do nothing
        }

        // removes models from current scene
        this.destroy()

        // loads new scene
        this.world.addModelsToScene(scene_dict.name,
            scene_url,
            scene_dict.position,
            scene_dict.scale,
            scene_dict.rotation,
            scene_dict.background)
        
        // console.log(this.scene)
        console.log('Loaded scene with name '+ scene_dict.name)
        this.scene_name = scene_dict.name
    }

    saveScene()
    {
        // clear gizmos like transform control
        this.transform.deactivate()
        this.shading.resetShading()

        if (this.world.environment.skybox || this.world.water){
            const response = confirm("Saving scene will remove the skybox/water for now. You can add it back later. Continue?");
            if (response) {
                this.world.environment.removeSkybox()
                this.world.removeWater()
            }
            else
            {
                return
            }
        }  

        // remove all characters
        if (this.poseManager.characters.length){
            const response = confirm("Saving scene won't save the characters in the scene. Characters will be removed. Continue?");
            if (response) {
                // do nothing
                this.poseManager.characters.forEach((char) => {
                    this.transform.select(char.object)
                    this.transform.deleteSelectedObject()
                })

                        // clear gizmos like transform control
                this.transform.deactivate()

                exportGLTF(this.scene, this.scene_name)
                // every time after save
                // stash might be changed
                // refresh scenes
            } else {
                return
            }
        }
        else {
            exportGLTF(this.scene, this.scene_name)
            // every time after save
            // stash might be changed
            // refresh scenes
        }

    }

    resize()
    {
        this.camera.resize()
        this.renderer.resize()
    }

    update()
    {
        this.camera.update()
        this.renderer.update()
    }

    destroy()
    {
        // this.sizes.off('resize')
        this.time.off('tick')
        this.world.transformableObjectArray = []
        this.world.shadeableObjectArray = []
        this.poseManager.characters = []

        // Traverse the whole scene
        var meshes = []
        this.scene.traverse((child) =>
        {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh && !(child.name == "SunGizmo"))
            {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)
                {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
            if(child instanceof THREE.Mesh || child instanceof THREE.Group){
                meshes.push(child)
            }
        })

        meshes.forEach(item => {
            if (item.parent)
            {item.parent.remove(item)}
        })

        // console.log('scene now has', this.scene)
        this.update()
        // this.camera.controls.dispose()
        // this.renderer.instance.dispose()

        // if(this.debug.active)
        //     this.debug.ui.destroy()
    }
}
