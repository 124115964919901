import Experience from '../Experience.js'

var UndoManager = require('undo-manager');

export default class Commands
{
    constructor()
    {
        this.experience = new Experience()
        this.transformManager = this.experience.transform
        this.transControl = this.experience.transform.transformControl
        this.world = this.experience.world
        this.undoManager = new UndoManager()
        this.objHistory = []

        this.oldObjData = null;
        this.newObjData = null;

        // undo transform object
        this.transControl.addEventListener( 'mouseDown', function(e) {
            this.oldObjData = getObjectData(this.transformManager.selected_object);
        }.bind(this) );

        this.transControl.addEventListener( 'mouseUp', function(e) {
            this.newObjData = getObjectData(this.transformManager.selected_object);
            this.addObj(this.transformManager.selected_object)
        }.bind(this) );

        this.transControl.addEventListener( 'dragging-changed', function ( e ) {
            if(e.value === false) { // End dragging
                this.addHistory(this.oldObjData, this.newObjData); // Store undo/redo
            }
        }.bind(this) );

        //undo add object
        this.world.on('ready', () =>
        {
            const obj = this.world.object_added
            this.undoManager.add({
                undo: function() {
                    if (obj==null) {console.log('Cant undo add: obj already deleted');return}
                    this.world.transformableObjectArray = this.world.transformableObjectArray.filter( ob => ob !== obj )
                    this.transformManager.selected_object = obj
                    this.transformManager.deleteSelectedObject()
                    // this.transformManager.deactivate()
                    // obj.visible = false
                    // this.experience.update()
                  }.bind(this),
                  redo: function() {
                    // this.experience.scene.add(obj)
                    // this.transformManager.deactivate()
                    // this.world.transformableObjectArray.push(obj)
                    // obj.visible = true
                    // this.experience.update()
                  }.bind(this)
            })
        })

        //undo selection change
        this.transformManager.on('selectionChanged', () =>
        {
            const last_obj = this.transformManager.deselected_object
            const now_obj = this.transformManager.selected_object
            this.undoManager.add({
                undo: function() {
                    if (last_obj == null) {console.log('Cant undo selection: obj deleted'); return}
                    if (last_obj.parent != undefined)
                    {
                        if (!this.transformManager.selectionMode){document.getElementById('selection-button').click()}
                        this.transformManager.deactivate()
                        this.transformManager.select(last_obj)}
                  }.bind(this),
                  redo: function() {
                    if (now_obj == null) {console.log('Cant undo selection: obj deleted'); return}
                    if (now_obj && now_obj.parent)
                    {
                        if (!this.transformManager.selectionMode){document.getElementById('selection-button').click()}
                        this.transformManager.deactivate()
                        this.transformManager.select(now_obj)
                    }
                  }.bind(this)
            })
        })

        document.onkeydown = function(e) {
            if (this.experience.renderer.shadingStyle != "none") {return}
            var evtobj = window.event? event : e
            if (evtobj.keyCode == 90 && (evtobj.ctrlKey || evtobj.metaKey) && evtobj.shiftKey)
            {
                evtobj.preventDefault()
                this.undoManager.redo()
            }
            else if (evtobj.keyCode == 90 && (evtobj.ctrlKey || evtobj.metaKey))  
            {
                evtobj.preventDefault()
                this.undoManager.undo()
            }
        }.bind(this)
    }

    addObj(obj){
        if(this.oldObjData && this.newObjData && this.oldObjData.uuid == this.newObjData.uuid) {
            this.objHistory.push(obj)
        }
    }

    addHistory() {
        if(this.oldObjData && this.newObjData && this.oldObjData.uuid == this.newObjData.uuid) {
            const oldObjData = this.oldObjData
            const newObjData = this.newObjData
            const objInd = this.objHistory.length
            this.undoManager.add({
                  undo: function() {
                    if (this.objHistory[objInd - 1] == null) {console.log('Cant undo transform: obj deleted'); return}
                    if (!this.transformManager.selectionMode){document.getElementById('selection-button').click()}
                    resetObject(this.objHistory[objInd - 1], oldObjData);
                    this.experience.update()
                  }.bind(this),
                  redo: function() {
                    if (this.objHistory[objInd - 1] == null) {console.log('Cant undo transform: obj deleted'); return}
                    if (!this.transformManager.selectionMode){document.getElementById('selection-button').click()}
                    resetObject(this.objHistory[objInd - 1], newObjData);
                    this.experience.update()
                  }.bind(this)
              });
          }
      }
}

function getObjectData(obj) {
    var data = {
          uuid: obj.uuid, // !Important, used in addHistory.
          position: {x: obj.position.x, y: obj.position.y, z: obj.position.z},
          rotation: {x: obj.rotation.x, y: obj.rotation.y, z: obj.rotation.z},
          scale: {x: obj.scale.x, y: obj.scale.y, z: obj.scale.z},
      };
      return data;
}

function resetObject(nowObj, data) {
    if (nowObj == undefined || nowObj == null) { return}
    nowObj.position.x = data.position.x;
    nowObj.position.y = data.position.y;
    nowObj.position.z = data.position.z;
    nowObj.rotation.x = data.rotation.x;
    nowObj.rotation.y = data.rotation.y;
    nowObj.rotation.z = data.rotation.z;
    nowObj.scale.x = data.scale.x;
    nowObj.scale.y = data.scale.y;
    nowObj.scale.z = data.scale.z;
}

