const shader = `
float blendDarken(float base, float blend) {
  return min(blend,base);
}

vec3 blendDarken(vec3 base, vec3 blend) {
  return vec3(blendDarken(base.r,blend.r),blendDarken(base.g,blend.g),blendDarken(base.b,blend.b));
}

vec3 blendDarken(vec3 base, vec3 blend, float opacity) {
  return (blendDarken(base, blend) * opacity + base * (1.0 - opacity));
}

float blendNormal(float base, float blend) {
  return (blend + base)/2.0;
}

vec3 blendNormal(vec3 base, vec3 blend) {
  return vec3(blendNormal(base.r,blend.r),blendNormal(base.g,blend.g),blendNormal(base.b,blend.b));
}

vec3 blendNormal(vec3 base, vec3 blend, float opacity) {
  return (blendNormal(base, blend) * opacity + base * (1.0 - opacity));
}`;

export { shader };
