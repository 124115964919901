/*
Clicks to select object, highlight object
Then can transform, rotate selected object
Clicks anywhere else to deselect object
*/

import * as THREE from 'three'
// import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { TransformControls } from '../Overwritethreejs/TransformControls.js'
import Experience from '../Experience.js'
import Prop from '../World/Prop.js'
import EventEmitter from './EventEmitter.js'

export default class TransformManager extends EventEmitter
{
    constructor()
    {
        super()

        this.experience = new Experience()
        this.scene = this.experience.scene
        this.renderer = this.experience.renderer
        this.camera = this.experience.camera
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.raycaster = new THREE.Raycaster()
        this.debug = this.experience.debug
        this.outlinepass = this.experience.renderer.outlinePass
        this.raycasterEnabled = true
        // this.raycaster.layers.set(1)
        this.pointer = new THREE.Vector2()
        this.selected_object = null
        this.deselected_object = null
        this.selectionMode = true

        this.setObjectOutline()

        this.setTransformControls()
    }

    setObjectOutline(){
        this.outlinepass.edgeStrength = 20;
        this.outlinepass.visibleEdgeColor.setColorName('red');
        this.outlinepass.edgeThickness = 5
        this.outlinepass.hiddenEdgeColor.setColorName('red');
    }

    setTransformControls(){
        this.transformControl = new TransformControls( this.camera.instance, this.canvas );
        this.transformControl.rotationSnap = Math.PI/16
        this.transformControl.addEventListener( 'change', ()=>{
            this.renderer.update()
        })

        this.transformControl.addEventListener( 'dragging-changed', function ( event ) {
            this.camera.controls.enabled = ! event.value
            this.raycasterEnabled = ! event.value
        }.bind(this) );

        this.canvas.addEventListener( 'pointerdown', function (event) {
            this.no_shading = !this.experience.shading.shadingActive && !this.experience.shading.ballpenshadingActive && !this.experience.shading.bwmangashadingActive && !this.experience.shading.cyberpunkActive
            this.onPointerDown(event, (this.selectionMode && this.no_shading && this.raycasterEnabled))
        }.bind(this) );

        document.body.addEventListener("keydown", function (ev) {
            if (this.experience.renderer.shadingStyle != "none") {return}
        // function to check the detection
        ev = ev || window.event;  // Event object 'ev'
        // ev.preventDefault()

        var key = ev.which || ev.keyCode; // Detecting keyCode
            
        // Detecting Ctrl
        // var ctrl = ev.ctrlKey ? ev.ctrlKey : ((key === 17) ? true : false);
        if (key == 86 && (ev.ctrlKey || ev.metaKey)) {
            this.paste()
        }
        else if (key == 67 && (ev.ctrlKey || ev.metaKey)) {
            this.copySelectedObject()
        }
        }.bind(this), false);
    }

    add( obj ){
        this.transformControl.attach( obj );
		this.scene.add( this.transformControl );
    }

    onPointerDown( event, enable ) {
        if (enable) {
            this.pointer.set( ( event.clientX / this.sizes.width ) * 2 - 1, - ( (event.clientY - this.canvas.offsetTop) / this.sizes.height ) * 2 + 1 );
            this.raycaster.setFromCamera( this.pointer, this.camera.instance );

            const intersects = this.raycaster.intersectObjects( this.experience.world.transformableObjectArray, true );

            if ( intersects.length == 0 && this.selected_object != null) {
                // nothing clicked
                // simply deselect and return
                this.dehighlightChildrenMaterial(this.selected_object)
                // this.outlinepass.selectedObjects = []
                this.deselected_object = this.selected_object
                this.selected_object = null;
                this.transformControl.detach();
                this.scene.remove(this.transformControl)

                this.trigger('selectionChanged')
                this.renderer.update()
                return
            }

            if ( intersects.length > 0) {
                const intersect = intersects[ 0 ].object.userData.parent;
                // console.log('clicked on obj', intersect)
                if (intersects[ 0 ].object == null){
                    // console.log('intersect object is null',intersect)
                    return
                }
                // console.log(intersect)

                if ( this.selected_object != null ) {
                    if ( intersect != this.selected_object ) {
                        // different object clicked
                        // deselect
                        this.dehighlightChildrenMaterial(this.selected_object)
                        this.deselected_object = this.selected_object
                        // this.outlinepass.selectedObjects = []
                        this.selected_object = null;
                        this.transformControl.detach();
                        this.scene.remove(this.transformControl)

                        // create selection
                        this.selected_object = intersect
                        this.highlightChildrenMaterial(this.selected_object)

                        // var selectedObjects = []
                        // selectedObjects.push(this.selected_object)
                        // this.outlinepass.selectedObjects = selectedObjects
                        this.transformControl.rotationSnap = Math.PI/10
                        this.add(this.selected_object)

                        // if this is a joint of a character,
                        // set this character to be poseable in pose manager
                        let character_to_select = null
                        this.selected_object.traverseAncestors((parent) =>
                        {
                            if (parent.userData.isCharacter)
                            {
                                this.transformControl.rotationSnap = undefined
                                character_to_select = parent.userData.characterInstance
                            }
                        })
                
                        if (character_to_select)
                        {
                            this.experience.poseManager.selectCharacter(character_to_select)
                        }

                        //for undo
                        this.trigger('selectionChanged')
                        this.renderer.update()
                        return
                    }
                    else
                    {
                        // same object clicked
                        // deselect
                        this.dehighlightChildrenMaterial(this.selected_object)
                        // this.outlinepass.selectedObjects = []
                        this.deselected_object = this.selected_object
                        this.selected_object = null;
                        this.transformControl.detach();
                        this.scene.remove(this.transformControl)
                        this.trigger('selectionChanged')
                        this.renderer.update()
                    }
                } else {        

                    // create selection
                    this.selected_object = intersect
                    // console.log(this.selected_object)
                    this.highlightChildrenMaterial(this.selected_object)
                    // var selectedObjects = []
                    // selectedObjects.push(this.selected_object)
                    // this.outlinepass.selectedObjects = selectedObjects
                    this.add(this.selected_object)
                    this.transformControl.rotationSnap = Math.PI/10
                    this.renderer.update()

                    // if this is a joint of a character,
                    // set this character to be poseable in pose manager
                    let character_to_select = null
                    this.selected_object.traverseAncestors((parent) =>
                    {
                        if (parent.userData.isCharacter)
                        {
                            this.transformControl.rotationSnap = undefined
                            character_to_select = parent.userData.characterInstance
                        }
                    })
            
                    if (character_to_select)
                    {
                        this.experience.poseManager.selectCharacter(character_to_select)
                    }
                }
        
            }
        }
    }

    select(obj){
        if (!obj) {return}
        if (!this.selectionMode){this.selectionBtn.click()}

        this.deactivate()

        this.selected_object = obj
        // console.log(this.selected_object)
        this.highlightChildrenMaterial(this.selected_object)
        // var selectedObjects = []
        // selectedObjects.push(this.selected_object)
        // this.experience.renderer.outlinePass.selectedObjects = selectedObjects
        this.add(this.selected_object)
        this.renderer.update()
    }

    deactivate(){
        if ( this.selected_object != null ) {
            this.dehighlightChildrenMaterial(this.selected_object)
            // this.experience.renderer.outlinePass.selectedObjects = []
            // this.deselected_object = this.selected_object
            this.selected_object = null;
        }
        this.transformControl.detach();
        this.scene.remove(this.transformControl)
        // this.trigger('selectionChanged')
        this.renderer.update()
    }

    copySelectedObject(){
        if (!this.selected_object){
            return
        }
        
        var is_character = false
        this.selected_object.traverseAncestors((parent) =>
        {
            if (parent.userData.isCharacter)
            {
                alert('Cant copy a character! Just spawn a new one.')
                is_character = true
                return
            }
        })
        if (is_character) {this.object_to_copy = null; return}
        this.object_to_copy = this.selected_object
    }

    paste(){
        this.deactivate()

        this.experience.shading.resetShading()

        if (this.object_to_copy == null) {return}

        var graphDict = {}
        var copy_root;
        graphDict[this.object_to_copy.parent.uuid] = this.object_to_copy.parent
        this.object_to_copy.traverse((child) =>{
            // first child is itself
            let model;
            if (child instanceof THREE.Mesh){
                model = new THREE.Mesh(child.geometry, child.material.clone());
            }
            else {
                model = new THREE.Group()
            }

            model.name = child.name
            model.position.copy(child.position)
            model.scale.copy(child.scale)
            model.rotation.copy(child.rotation)

            graphDict[child.uuid] = model
            graphDict[child.parent.uuid].add(model)

            if (child == this.object_to_copy)
            {
                copy_root = model
            }
        })

        this.experience.world.transformableObjectArray.push(copy_root)
        this.experience.world.shadeableObjectArray.push(copy_root)
        var newObj = new Prop(this.object_to_copy.name,null,
            [0,0,0],
            [1,1,1],
            [0,0,0], false, copy_root, null)
        
        this.deselected_object = this.object_to_copy
        this.select(copy_root)
        this.trigger('selectionChanged')
        this.experience.world.object_added = copy_root
        this.experience.world.trigger('ready')
    }

    deleteSelectedObject(){
        if (!this.selected_object){
            return
        }
        this.experience.shading.resetShading()
        this.dehighlightChildrenMaterial(this.selected_object)
        this.transformControl.detach();
        this.scene.remove(this.transformControl)

        //check to see if this object is part of a character
        //if yes, delete the whole character
        let character_to_delete = null
        this.selected_object.traverseAncestors((parent) =>
        {
            if (parent.userData.isCharacter)
            {
                character_to_delete = parent
            }
        })

        if (character_to_delete)
        {
            var meshes = []
            character_to_delete.traverse((child) =>
            {
                // Test if it's a mesh
                if(child instanceof THREE.Mesh)
                {
                    // console.log('destroying mesh', child)
                    child.geometry.dispose()
    
                    // Loop through the material properties
                    for(const key in child.material)
                    {
                        const value = child.material[key]
    
                        // Test if there is a dispose function
                        if(value && typeof value.dispose === 'function')
                        {
                            value.dispose()
                        }
                    }
                }
                if(child instanceof THREE.Mesh || child instanceof THREE.Group ||  child instanceof THREE.Bone){
                    meshes.push(child)
                }
            })
    
            meshes.forEach(item => {
                if (item.parent)
                {
                    // console.log('removing from parent', item)
                    item.parent.remove(item)
                }
            })
            this.renderer.instance.renderLists.dispose();
            this.selected_object = null;

            this.renderer.update()
            return
        }

        // delete meshes and materials
        var meshes = []
        this.selected_object.traverse((child) =>
        {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh)
            {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)
                {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
            meshes.push(child)
        })

        meshes.forEach(item => {
            if (! (item.parent === undefined || item.parent === null))
            {item.parent.remove(item)}
            item = undefined
        })

        this.selected_object = null;
        this.renderer.update()
    }

    highlightChildrenMaterial( obj ) {
        obj.traverse((child) =>
        {
            if (child.material && child.material.emissive) 
            {
                child.currentHex = child.material.emissive.getHex();
                child.material.emissive.setHex( 0xffffff );
                child.material.emissiveIntensity = 0.2
            }
            if (child.userData.gui)
            {
                child.userData.gui.show()
            }
        })
        if (obj.userData.thisGui)
        {
            obj.userData.thisGui.show()
        }
    }

    dehighlightChildrenMaterial( obj ) {
        obj.traverse((child) =>
        {
            if (child.material && child.material.emissive) 
            {
                child.material.emissive.setHex( child.currentHex );
            }
            if (child.userData.gui)
            {
                child.userData.gui.hide()
            }
        })
        if (obj.userData.thisGui)
        {
            obj.userData.thisGui.hide()
        }
    }
}