import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAw58Irb6Avk2s7os5LYWjS3ispTd-J9Hc",
    authDomain: "kurukuru-f7c9e.firebaseapp.com",
    projectId: "kurukuru-f7c9e",
    storageBucket: "kurukuru-f7c9e.appspot.com",
    messagingSenderId: "584359670431",
    appId: "1:584359670431:web:b9a41faca4bc415a6aa8aa",
    measurementId: "G-8Z7EK87ZDL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const creatorFolder = 'creators/test-creator/scenes/'