// creating this class to reduce the number of draw calls
// only redraw 3D scene when some kind of control, camera, or render conditions change
// significantly reduces compute!!!

import * as THREE from 'three'

import Experience from './Experience.js'

export default class RenderManager
{
    constructor()
    {
        this.experience = new Experience()
        this.camera = this.experience.camera
        this.renderer = this.experience.renderer
        this.world = this.experience.world

        this.controls = this.experience.camera.controls
        this.controls.addEventListener( 'change', this.render.bind(this) );

        this.transform = this.experience.transform.transformControl
        this.transform.addEventListener( 'change', this.render.bind(this) );
        this.transform.addEventListener( 'dragging-changed', this.render.bind(this) );
        this.transform.addEventListener( 'mouseDown' , this.render.bind(this) );
        this.transform.addEventListener( 'mouseUp', this.render.bind(this) );
        this.transform.addEventListener( 'objectChange', this.render.bind(this) );

        this.shading = this.experience.shading
        this.shading.on( 'change', this.render.bind(this) );

        // this.experience.world.on('ready', () =>
        // {
        //     this.experience.world.char.on('ready', () =>
        //     {
        //         this.experience.world.char.on( 'change', this.render.bind(this) );
        //     })
        // })

        this.debugui  =this.experience.debug.ui
        this.debugui.onChange(this.render.bind(this))
    }

    render()
    {
        this.experience.renderer.update()
    }
}