import Experience from "../Experience.js";
import CapturedImg from "./CapturedImg.js";

// if this value changes, drawing will break. look up this value in sizes.js
// TODO: use reference from sizes.js
// my javascript knowledge is failing me

export default class PanelDrawer {
    constructor() {
        this.experience = new Experience();
        this.camera = this.experience.camera;

        // not the .webgl canvas, but a 2d canvas overlaying on top for drawing
        this.canvas = document.querySelector("canvas.overlayOnWebgl");
        //this.canvas = null
        this.canvasZIndex = this.canvas.style.zIndex;
        this.context = null;

        this.transform = this.experience.transform;

        this.rectangle = null;

        this.isDown = false;
        this.rectangleVisible = false;
        this.lastX = null;
        this.lastY = null;

        this.pixelRatio = Math.min(window.devicePixelRatio, 2);
        this.capturedImg = null;

        this.drawRectangleHandler = this.drawRectangle.bind(this);
        this.dragDrawRectangleHandler = this.dragDrawRectangle.bind(this);
        this.finishRectangleHandler = this.finishRectangle.bind(this);
        this.captureRectangleHandler = this.captureRectangle.bind(this);
    }

    enableDrawRectangle() {
        // this.canvas = document.createElement('canvas').classList.add('.overlayOnWebgl')

        this.context = this.canvas.getContext("2d");
        this.context.canvas.width = window.innerWidth;
        this.context.canvas.height = window.innerHeight;

        console.log(this.canvas);
        console.log(this.context);

        this.transform.raycasterEnabled = false; // disable transform control

        if (this.transform.selected_object != null) {
            this.transform.dehighlightChildrenMaterial(this.transform.selected_object);
            this.transform.selected_object = null;
            this.transform.transformControl.detach();
            this.transform.scene.remove(this.transform.transformControl);
        }
        this.camera.controls.enabled = false; // disable camera orbit

        this.canvas.addEventListener("pointerdown", this.drawRectangleHandler, false);
        this.canvas.addEventListener("pointermove", this.dragDrawRectangleHandler, false);
        this.canvas.addEventListener("pointerup", this.finishRectangleHandler, false);
        this.canvas.style.zIndex = this.canvasZIndex;
    }

    disableDrawRectangle() {
        if (this.context) {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }

        this.canvas.removeEventListener("pointerdown", this.drawRectangleHandler, false);
        this.canvas.removeEventListener("pointermove", this.dragDrawRectangleHandler, false);
        this.canvas.removeEventListener("pointerup", this.finishRectangleHandler, false);

        this.transform.raycasterEnabled = true; // disable transform control
        this.camera.controls.enabled = true; // disable camera orbit
        this.canvas.style.zIndex = "-1";
        this.rectangleVisible = false;
    }

    drawRectangle(event) {
        event.preventDefault();
        event.stopPropagation();

        this.isDown = true;

        console.log(this.rectangleVisible, this.isDragging);

        if (this.rectangleVisible) {
            this.isDragging = true;
            return;
        }

        // this.pointer.set( ( event.clientX / this.sizes.width ) * 2 - 1, - ( (event.clientY - this.canvas.offsetTop ) / this.sizes.height ) * 2 + 1 );

        // hit test existing rectangle
        this.lastX = event.clientX - this.canvas.offsetLeft;
        this.lastY = event.clientY - this.canvas.offsetTop;

        var hit = -1;

        // supposedly, if clicking on an existing rectangle, should be able to transform it
        // add this logic later
        // if (this.rectangle){
        //     const disX = Math.abs(lastX - this.rectangle.getCenterX())
        //     const disY = Math.abs(lastY - this.rectangle.getCenterY())
        //     if (disY > (this.rectangle.getHeight() / 2) && disX > (this.rectangle.getWidth() / 2) &&
        //     disY < (this.rectangle.getHeight() / 2 + this.rectangle.lineWidth) && disX < (this.rectangle.getWidth() / 2 + this.rectangle.lineWidth)
        //     ){
        //         hit = 1
        //     }
        // }

        // if no hits then add a rectangle
        // if hit then set the isDown flag to start a drag
        this.rectangle = new Rectangle(this.lastX, this.lastY, this.lastX, this.lastY);
        this.isDown = true;
        this.draw();
    }

    dragDrawRectangle(event) {
        // tell the browser we'll handle this event
        event.preventDefault();
        event.stopPropagation();

        if (this.rectangleVisible && this.isDragging) {
            console.log("should be dragging");
            const mouseX = event.clientX - this.canvas.offsetLeft;
            const mouseY = event.clientY - this.canvas.offsetTop;
            this.rectangle.firstX = mouseX;
            this.rectangle.firstY = mouseY;

            this.lastX = this.rectangle.firstX + this.drawCanvasWidth;
            this.lastY = this.rectangle.firstY + this.drawCanvasHeight;
            this.rectangle.secondX = this.lastX;
            this.rectangle.secondY = this.lastY;

            this.draw();

            return;
        }

        // if we're not dragging, just exit
        if (!this.isDown) {
            return;
        }

        // get the current mouse position
        const side = event.clientY - this.canvas.offsetTop - this.rectangle.firstY;
        this.lastX = this.rectangle.firstX + side;
        this.lastY = this.rectangle.firstY + side;

        // change the target circles position by the
        // distance the mouse has moved since the last
        // mousemove event
        this.rectangle.secondX = this.lastX;
        this.rectangle.secondY = this.lastY;

        // redraw all the circles
        this.draw();
    }

    draw() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.context.beginPath();
        this.context.lineWidth = this.rectangle.lineWidth;
        this.context.strokeStyle = "#202020";
        this.context.rect(
            Math.min(this.rectangle.firstX, this.rectangle.secondX),
            Math.min(this.rectangle.firstY, this.rectangle.secondY),
            this.rectangle.getWidth(),
            this.rectangle.getHeight()
        );
        this.context.stroke();
        this.context.closePath();
    }

    finishRectangle(event) {
        // tell the browser we'll handle this event
        event.preventDefault();
        event.stopPropagation();

        // stop the drag
        this.isDown = false;
        this.rectangleVisible = true;
        this.isDragging = false;

        this.drawCanvasHeight = this.rectangle.getHeight();
        this.drawCanvasWidth = this.rectangle.getWidth();

        this.captureRectangle();
        this.dragDropRectangle();
        // make rectangle draggable
    }

    captureRectangle() {
        //TODO, take screenshot of .webgl canvas with current this.rectangle data
        // fill rectangle with it? and then allow drag dropping into div?

        if (
            this.rectangle.firstX == this.rectangle.secondX ||
            this.rectangle.firstY == this.rectangle.secondY
        ) {
            return;
        }
        var cv2 = document.createElement("canvas");
        cv2.width = this.rectangle.getWidth();
        cv2.height = this.rectangle.getHeight();
        var ctx2 = cv2.getContext("2d");

        var capturedImg = document.createElement("img");
        var srcCanvas = document.getElementById("mainCanvas");

        ctx2.drawImage(
            srcCanvas,
            Math.min(this.rectangle.firstX, this.rectangle.secondX) * this.pixelRatio,
            Math.min(this.rectangle.firstY, this.rectangle.secondY) * this.pixelRatio,
            cv2.width * this.pixelRatio,
            cv2.height * this.pixelRatio,
            0,
            0,
            cv2.width,
            cv2.height
        );
        const dataURI = cv2.toDataURL("image/jpeg", 1.0);

        capturedImg.src = dataURI;

        // TODO: customize image border
        capturedImg.style.border = "3px solid black";

        // TODO: customize image margin
        capturedImg.style.margin = "5px";

        capturedImg.draggable = true;

        this.capturedImg = new CapturedImg(capturedImg);

        document.getElementById("generate-source-preview").src = this.capturedImg.image.src;
    }

    dragDropRectangle(event) {}
}

class Rectangle {
    constructor(firstX, firstY, secondX, secondY, lineWidth = 2) {
        this.firstX = firstX;
        this.firstY = firstY;
        this.secondX = secondX;
        this.secondY = secondY;
        this.lineWidth = lineWidth;
    }

    getCenterX() {
        return (this.firstX + this.secondX) / 2;
    }

    getCenterY() {
        return (this.firstY + this.secondY) / 2;
    }

    getWidth() {
        return Math.abs(this.firstX - this.secondX);
    }

    getHeight() {
        return Math.abs(this.firstY - this.secondY);
    }
}
