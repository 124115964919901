import ImageContextMenu from "./ImageContextMenu"

export default class CapturedImg
{
  constructor(imgElement)
  {
    this.image = imgElement
    this.contextMenu = new ImageContextMenu(this.image)

    this.contextMenu.openContextMenu()
    this.contextMenu.closeContextMenu()
    //TODO: add more than one speechbubble
    //this.speechBubble = new SpeechBubble(this.image)
  }
}