export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'textures/environmentMap/px.jpg',
            'textures/environmentMap/nx.jpg',
            'textures/environmentMap/py.jpg',
            'textures/environmentMap/ny.jpg',
            'textures/environmentMap/pz.jpg',
            'textures/environmentMap/nz.jpg'
        ]
    },
    {
        name: 'environmentMapTextureDawn',
        type: 'cubeTexture',
        path:
        [
            'textures/environmentMapDawn/px.png',
            'textures/environmentMapDawn/nx.png',
            'textures/environmentMapDawn/py.png',
            'textures/environmentMapDawn/ny.png',
            'textures/environmentMapDawn/pz.png',
            'textures/environmentMapDawn/nz.png'
        ]
    },
    {
        name: 'grassColorTexture',
        type: 'texture',
        path: 'textures/dirt/Wallpaper_04_diffuse.png'
    },
    {
        name: 'grassNormalTexture',
        type: 'texture',
        path: 'textures/dirt/normal.jpg'
    },
    {
        name: 'toonGradientTexture3',
        type: 'texture',
        path: 'textures/gradients/3.jpg'
    },
    {
        name: 'toonGradientTexture5',
        type: 'texture',
        path: 'textures/gradients/5.jpg'
    },
]