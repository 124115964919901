import { storage } from "./FirebaseClient.js"
import { getAuth } from "firebase/auth";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import stash from '../../stash.json'
import EventEmitter from "./EventEmitter.js";
import Experience from "../Experience.js";

function loadJSONfromUrl(url) {
    fetch(url)
        .then(res => res.json())
        .then((out) => {
            console.log(out)
        }).catch(err => console.error(err));
}

export default class StashManager extends EventEmitter {
    constructor() {
        super()
        this.experience = new Experience()
        this.transform = this.experience.transform;
        this.buttons = null;

        //this.stash_url = "creators/test-creator/stash.json"
        //this.stash = stash
        this.stash = null
        // this.stash is assigned in this.loadStash()
        this.loadStash()
        this.searchStash();
        this.clearSearch();
    }

    // Download stash.json from firebase storage and load it as a JS object
    loadStash() {
        const auth = getAuth();
        auth.onAuthStateChanged(function (user) {
            if (user) {
                getDownloadURL(ref(storage, 'creators/' + user.uid + '/stash.json'))
                    .then((url) => {
                        fetch(url)
                            .then(res => res.json())
                            .then((out) => {
                                this.stash = out;
                                console.log(out)
                                this.trigger('StashLoaded')
                                this.refreshStash()
                                this.experience.buttons.addModelFolders();

                                this.refreshUploads(user.uid)

                                this.on('MyPropsRefreshed', () =>
                                {
                                    this.experience.buttons.reloadUploadFolders()
                                })

                                this.experience.buttons.addCharacters()
                            }).catch(err => console.error(err));
                    })
                    .catch((error) => {
                        // Uh-oh, an error occurred!
                    });

            } else {
                alert('Not signed in')
                window.location.href = '/login.html';
            }
        }.bind(this));
    }

    imageValidation(fileName) {

        // Allowing file type
        var imageExtensions =
            /(\.jpg|\.jpeg|\.png)$/i;

        if (!imageExtensions.exec(fileName)) {
            return false;
        }
        else {
            return true;
        }
    }

    _3DmodelValidation(fileName) {

        var _3DmodelExtensions =
            /(\.glb)$/i;

        if (!_3DmodelExtensions.exec(fileName)) {
            return false;
        }
        else {
            return true;
        }
    }

    refreshUploads(uid) {
        //add user uploaded models to stash
        this.stash.assets.scenes.length = 0
        const listRef = ref(storage, 'creators/' + uid + '/upload');
        console.log("listRef: ", listRef)
        listAll(listRef)
            .then((res) => {
                const listFolders = res.prefixes.map((prefix) => prefix.fullPath);
                for (var i = 0; i < listFolders.length; i++) {
                    listAll(ref(storage, listFolders[i]))
                        .then((res) => {
                            res.items.forEach((itemRef) => {
                                //console.log("items: ", itemRef)
                                var gcs_path = itemRef._location.path_

                                if (!this.stash.assets.models.some(m => m.gcs_path === gcs_path)) {
                                    if (this.imageValidation(gcs_path)) {
                                        var newPlaneTexture = {
                                            "name": gcs_path.match('.*(\/(.*\).(jpe?g|png))')[2],
                                            "type": "planeTexture",
                                            "gcs_path": gcs_path,
                                            "thumbnail_gcs_path": gcs_path,
                                            "background": false,
                                            "scale": 1,
                                            "rotation": [0, 0, 0],
                                            "position": [0, 0, 0],
                                        }

                                        this.stash.assets.models.push(newPlaneTexture)
                                    }

                                    if (this._3DmodelValidation(gcs_path)) {
                                        console.log("gcs_path: ", gcs_path)
                                        var newModel = {
                                            "name": gcs_path.match('.*(\/(.*\).glb)')[2],
                                            "type": "glbModel",
                                            "gcs_path": gcs_path,
                                            "thumbnail_gcs_path": "misc/default_thumbnail.png",
                                            "background": false,
                                            "scale": 1,
                                            "rotation": [0, 0, 0],
                                            "position": [0, 0, 0],
                                        }

                                        this.stash.assets.models.push(newModel)
                                    }
                                }
                            });

                            this.trigger('MyPropsRefreshed')
                        }).catch((error) => {
                            console.log("Error listing files in upload subfolders: ", error);
                        });
                }
            }).catch((error) => {
                console.log("Error listing files in upload folders: ", error);
            });
    }

    refreshStash() {
        // stash has three components: models, characters and scenes,
        // right now we manually assign models and characters to creators
        // and each creator's model and characters don't change
        // only scenes need to be refreshed each time they open the app

        // this function loads stash json
        // loads scenes from creator folder on firebase storage
        // adds these scenes to stash
        // and provide scenes to frontend UI
        this.stash.assets.scenes.length = 0
        const auth = getAuth();
        auth.onAuthStateChanged(function (user) {
            if (user) {
                //const listRef = ref(storage, 'creators/test-creator/scenes');
                const listRef = ref(storage, 'creators/' + user.uid + '/scenes');
                listAll(listRef)
                    .then((res) => {
                        res.items.forEach((itemRef) => {
                            var gcs_path = itemRef._location.path_
                            var newScene = {
                                "name": gcs_path.match('.*(\/(.*\).glb)')[2],
                                "type": "glbModel",
                                "gcs_path": gcs_path,
                                "background": false,
                                "scale": 1,
                                "rotation": [0, 0, 0],
                                "position": [0, 0, 0],
                            }

                            this.stash.assets.scenes.push(newScene)
                        });
                        this.trigger('StashRefreshed')
                        this.experience.buttons.addScenes()

                    }).catch((error) => {
                        // Uh-oh, an error occurred!
                    });
            } else {
                alert('Not signed in')
                window.location.href = '/login.html';
            }
        }.bind(this));
    }

    getAssetTypeName(path){
        const slashIndexes = [];

        Array.from(path).forEach((char, index)=>{
            if (slashIndexes.length < 2) {
                if (char === '/'){
                    slashIndexes.push(index)
                }
            }
        });

        return path.substring(slashIndexes[0]+1, slashIndexes[1])
    }

    clearSearch() {
        const clearButton = document.getElementById('close-search-button');
        const serachResults = document.getElementById('search-results');
        const input = document.getElementById('stash-search-input');
        const folders = document.getElementsByClassName('modelFolders');
        

        clearButton.addEventListener('click', () => {
            Array.prototype.forEach.call(folders, folder => {
                folder.hidden = true;
            })
            if (this.buttons === null) {
                this.buttons = this.experience.buttons;
            }
            this.buttons.modelList.hidden = false;
            this.buttons.characterList.hidden = true;
            // this.buttons.sceneList.hidden = true;
            const folderList = document.getElementById('add-model-folder-list');
            const buttonBox = document.getElementById('button-container-box');
            input.value = ''
            serachResults.innerHTML = ""
            folderList.hidden = false   
            buttonBox.style = 'display: flex';
        })
    }

    searchStash() {
        const input = document.getElementById('stash-search-input');
        const serachResults = document.getElementById('search-results');

        
        input.addEventListener('input', (ev)=>{
            const folderList = document.getElementById('add-model-folder-list');
            const buttonBox = document.getElementById('button-container-box');
            // const folders = document.getElementsByClassName('modelFolders');
            // ignore casing when looking through things
            const query = ev.target.value.toLowerCase();
            serachResults.innerHTML = ""
            if (this.stash.assets && query.length > 0) {
                // Array.prototype.forEach.call(folders, folder => {
                //     folder.hidden = true;
                // })
                folderList.hidden = true
                buttonBox.style = 'display: none';
                if (this.buttons === null) {
                    this.buttons = this.experience.buttons;
                }
                this.buttons.characterList.hidden = true;
                // this.buttons.sceneList.hidden = true;
                this.buttons.modelList.hidden = true;

                //////////////////////////////////////////////////
                console.log('//------------------------------')
                const {characters, models} = this.stash.assets;
                const collection = new Set();

                const results = [];

                const resultsMap = new Map();
    
                // characters.forEach(character => {
                //     const resultType = this.getAssetTypeName(character.gcs_path);
                //     if (character.name.toLowerCase().includes(query)){
                //         results.push(`${resultType} - ${character.name}`);
                //         const itemsInType = resultsMap.get(resultType) ?? [];
                //         itemsInType.push(character.name);
                //         resultsMap.set(resultType, itemsInType)
                //     }
                //     if (resultType.includes(query)){
                //         collection.add(resultType.toLowerCase())
                //     }
                // })

                models.forEach(model => {
                    const resultType = this.getAssetTypeName(model.gcs_path);
                    if (model.name.toLowerCase().includes(query)){
                        results.push(`${resultType} - ${model.name}`);
                        const itemsInType = resultsMap.get(resultType) ?? [];
                        itemsInType.push(model);
                        resultsMap.set(resultType, itemsInType)
                    }
                    if (resultType.includes(query)){
                        collection.add(resultType.toLowerCase())
                    }
                });

                // console.log(Array.from(collection))
                // resultsMap.set('Collection', Array.from(collection));


                resultsMap.forEach((models, key) => {
                    const resultSet = document.createElement('div');
                    const title = document.createElement('p');
                    title.className = 'search-result-title';
                    const resultList = document.createElement('div');
                    const folderDiv = document.createElement("div");
                    resultList.appendChild(title)
                    resultList.className = 'search-result-list';
                    title.textContent = key;
                    console.log(models, key);
                    models.forEach(model=>{
                        this.addModel(model, resultList)
                    })
                    resultSet.appendChild(resultList);
                    serachResults.appendChild(resultSet);
                    });
                
                
                console.log(resultsMap);
            } else {
                folderList.hidden = false
                buttonBox.style = 'display: flex';
            }
            
        })
    }
    addModel(model,folder){
        if (model.type == "gltfModel" || model.type == "glbModel")
                {
                    const tag = document.createElement("a");
                    const text = document.createElement('p');
                    text.innerText = model.name;
                    tag.className = "propNode"
                    const img = document.createElement('img');
                    img.className = "propImg"
                    img.loading = "lazy"
                    const loadingText = document.createElement('l');
                    loadingText.innerText = "";
                    img.setAttribute('src', 'images/loading_t.gif')
                    tag.appendChild(img);
                    tag.appendChild(text);
                    tag.appendChild(loadingText)

                    getDownloadURL(ref(storage, model.thumbnail_gcs_path))
                        .then((url) => {
                            img.setAttribute('src', url)
                    })
                    folder.appendChild(tag);

                    tag.onclick = function() {
                        img.style.opacity = 0.2
                        loadingText.innerText = "Loading..."
                        tag.style.pointerEvents = "none"

                        if (!this.transform.selectionMode){this.selectionBtn.click()}
                        getDownloadURL(ref(storage, model.gcs_path))
                        .then((url) => {
                            this.experience.world.addModelToScene( model.name,
                                url,
                                model.position,
                                model.scale,
                                model.rotation,
                                model.background,
                                false,
                                model.texture_gcs_folder)
                            this.experience.world.on(model.name +' ready', () =>
                                {
                                    img.style.opacity = 1
                                    loadingText.innerText = ""
                                    tag.style.pointerEvents = "auto"
                                })
                        })
                        .catch((error) => {
                            img.style.opacity = 1
                            loadingText.innerText = ""
                            tag.style.pointerEvents = "auto"
                            // Handle any errors
                        });
                        
                        }.bind(this);
                }

            if (model.type == "planeTexture")
            {
                const tag = document.createElement("a");
                const text = document.createElement('p');
                text.innerText = model.name;
                tag.className = "propNode"
                const img = document.createElement('img');
                img.className = "propImg"
                img.loading = "lazy"
                const loadingText = document.createElement('l');
                loadingText.innerText = "";

                getDownloadURL(ref(storage, model.thumbnail_gcs_path))
                    .then((url) => {
                        img.setAttribute('src', url)
                        tag.appendChild(img);
                        tag.appendChild(text);
                        tag.appendChild(loadingText);
                })
                folder.appendChild(tag);
                tag.onclick = function() {
                    img.style.opacity = 0.2
                    loadingText.innerText = "Loading..."
                    tag.style.pointerEvents = "none"
                    
                    if (!this.transform.selectionMode){this.selectionBtn.click()}
                    getDownloadURL(ref(storage, model.gcs_path))
                    .then((url) => {
                        this.experience.world.addWallToScene( model.name,
                            url,
                            model.position,
                            model.scale,
                            model.rotation,
                            model.background,
                            false)
                        this.experience.world.on(model.name +' ready', () =>
                        {
                            img.style.opacity = 1
                            loadingText.innerText = ""
                            tag.style.pointerEvents = "auto"
                        })
                    }).catch((error) => {
                        img.style.opacity = 1
                        loadingText.innerText = ""
                        tag.style.pointerEvents = "auto"
                        // Handle any errors
                    });
                    }.bind(this);
            }

            if (model.type == "skybox")
            {
                const tag = document.createElement("a");
                const text = document.createElement('p');
                text.innerText = model.name;
                tag.className = "propNode"
                const img = document.createElement('img');
                img.className = "propImg"
                img.loading = "lazy"

                getDownloadURL(ref(storage, model.thumbnail_gcs_path))
                    .then((url) => {
                        img.setAttribute('src', url)
                        tag.appendChild(img);
                        tag.appendChild(text);
                })
                folder.appendChild(tag);
                tag.onclick = function() {
                    getDownloadURL(ref(storage, model.gcs_path))
                    .then((url) => {
                        this.experience.world.environment.setSkybox(url)

                    })
                    }.bind(this);
            }

            if (model.type == "water")
            {
                const tag = document.createElement("a");
                const text = document.createElement('p');
                text.innerText = model.name;
                tag.className = "propNode"
                const img = document.createElement('img');
                img.className = "propImg"
                img.loading = "lazy"

                getDownloadURL(ref(storage, model.thumbnail_gcs_path))
                    .then((url) => {
                        img.setAttribute('src', url)
                        tag.appendChild(img);
                        tag.appendChild(text);
                })
                folder.appendChild(tag);
                tag.onclick = function() {
                    getDownloadURL(ref(storage, model.gcs_path))
                    .then((url) => {
                        this.experience.world.buildWater(url)

                    })
                    }.bind(this);
            }
    }
}