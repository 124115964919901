import * as THREE from 'three'
import { CubeTextureLoader } from 'three'
import Experience from '../Experience.js'
// import { Water } from 'three/examples/jsm/objects/Water.js';
import { Water} from 'three/examples/jsm/objects/Water2.js';

export default class Environment
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        //skybox
        this.textureloader = new THREE.TextureLoader()
        // this.scene.fog = new THREE.FogExp2('#ffffff', 0.1)
        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('Lighting')
        }


        //sun object
        // const color = new THREE.Color("#FDB813");
        // const geometry = new THREE.IcosahedronGeometry(0.5, 15);
        // const material = new THREE.MeshStandardMaterial({ color: color });
        // this.sunGizmo = new THREE.Mesh(geometry, material);
        // this.sunGizmo.userData.parent = this.sunGizmo
        // this.sunGizmo.name = "SunGizmo"
        // this.sunGizmo.position.set(4, 8, 0);
        // this.scene.add(this.sunGizmo);
        
        this.morninglight()
        // this.buildWater()

        var envDict = {morning:"Morning", moonlight:"Moonlight"}

		// add pose names to pose panel for selection
		var lightpanel = document.getElementById("lightpanel");

		for (let k in envDict) {
			const envName = envDict[k]
			var tag = document.createElement("a");
            tag.className = 'folderName'
			var text = document.createTextNode(envName);
			tag.appendChild(text);
			lightpanel.appendChild(tag);
			tag.onclick = function() {
                if (envName == 'Morning')
                {
                    this.morninglight()
                }
                if (envName == "Moonlight")
                {
                    this.nightlight()
                }
            }.bind(this)
		}
    }

    removeSkybox(){
        if (this.skybox){
            //destroy old skybox
            // Loop through the material properties
            for(const key in this.skybox.material)
            {
                const value = this.skybox.material[key]

                // Test if there is a dispose function
                if(value && typeof value.dispose === 'function')
                {
                    value.dispose()
                }
            }
            this.scene.remove(this.skybox)
            this.skybox.geometry.dispose();
            this.skybox = undefined
            this.experience.renderer.update()
        }
    }

    setSkybox(path){
        // undo shading because we'll add one more objs to shadeable object array
        this.experience.shading.resetShading()

        this.removeSkybox()

        this.textureloader.load(
            // resource URL
            path,
            // onLoad callback
            function ( texture ) {
                texture.encoding = THREE.sRGBEncoding

                this.skyMaterial  = new THREE.MeshBasicMaterial({map:texture})
                this.skyMaterial.needsUpdate = true
                this.skyMaterial.side = THREE.BackSide;
                this.skyMaterial.fog = false
                this.skyboxGeo = new THREE.SphereBufferGeometry(400);
                this.skybox = new THREE.Mesh( this.skyboxGeo, this.skyMaterial);
                this.skybox.name = "skybox"
                this.skybox.position.set(0,-50,0)
                this.scene.add(this.skybox)
                this.experience.world.shadeableObjectArray.push(this.skybox)
                this.experience.world.object_added = this.skybox
                // console.log('set skybox')
                this.experience.renderer.update()
            }.bind(this)
            )
    }

    morninglight()
    {
        if (this.skybox)
        {
            this.scene.remove(this.skybox)
        }
        if (this.sunLight)
        {
            this.scene.remove(this.sunLight)
            this.scene.remove(this.directional2)
        }
        if (this.ambientLight)
        {
            this.scene.remove(this.ambientLight)
        }
        if (this.scene.fog)
        {
            this.scene.fog = null
        }
        if(this.debug.active)
        {
            this.debugFolder.destroy()
            this.debugFolder = this.debug.ui.addFolder('Lighting')
        }
        this.setSunLight('#ffd7e8',4)
        // this.skybox = new Skybox(this.scene,'morning',this.debug,this.experience).skybox
        this.setAmbientLight('#ffffff', 0.01)
        this.setEnvironmentMap('dawn', 1.6)

        // let fog = new Fog(this.scene,'#64566c',1,200,this.debug)
        this.experience.renderer.update()
    }

    nightlight()
    {
        if (this.skybox)
        {
            this.scene.remove(this.skybox)
        }
        if (this.sunLight)
        {
            this.scene.remove(this.sunLight)
            this.scene.remove(this.directional2)
        }
        if (this.ambientLight)
        {
            this.scene.remove(this.ambientLight)
        }
        if (this.scene.fog)
        {
            this.scene.fog = null
        }
        if(this.debug.active)
        {
            this.debugFolder.destroy()
            this.debugFolder = this.debug.ui.addFolder('Lighting')
        }
        this.setSunLight('#ffffff',0.3,-10,20,10)
        // this.skybox = new Skybox(this.scene,'night',this.debug,this.experience).skybox
        this.setAmbientLight('#114291', 0.3)
        this.setEnvironmentMap('dawn', 0)
        // this.setStreetLamp(20,-5,-20,1,0,0)
        // this.setStreetLamp(-1,6,2,1,0,-3)
        // this.setStreetLamp(-1,6,-18,1,0,-23)
        // this.setStreetLamp(-1,6,52,1,0,47)
        this.experience.renderer.update()
    }

    setAmbientLight(color,intensity)
    {
        this.ambientLight = new THREE.AmbientLight(color,intensity)
        this.debugFolder
        .add(this.ambientLight, 'intensity')
        .name('Ambient Light Intensity')
        .min(0)
        .max(30)
        .step(0.1)

        this.debugFolder.addColor( this.ambientLight, 'color').name('Ambient Light Color')
    
        this.scene.add(this.ambientLight)
    }

    setSunLight(color,intensity, x=8, y=8, z=0)
    {
        this.sunLight = new THREE.DirectionalLight(color, intensity)
        this.sunLight.castShadow = false
        this.sunLight.shadow.camera.near = 0.05
        this.sunLight.shadow.camera.far = 100
        const m = 100.0
        this.sunLight.shadow.camera.left = m/-2
        this.sunLight.shadow.camera.right = m/2
        this.sunLight.shadow.camera.top = m/2
        this.sunLight.shadow.camera.bottom = m/-2
        this.sunLight.shadow.camera.updateProjectionMatrix()
        this.sunLight.shadow.mapSize.set(1024*2, 1024*2)
        // normal bias
        this.sunLight.shadow.normalBias = 0.03
        // bias needs to be negative to prevent banding in shadows
        this.sunLight.shadow.bias = -0.003
		// this.sunLight.shadow.bias = 0.003
        // this.helper = new THREE.CameraHelper( this.sunLight.shadow.camera );
        // this.scene.add( helper );

        // console.log(this.sunLight.shadow.bias)
        this.sunLight.position.set(x, y, z)
        // this.sunGizmo.add(this.sunLight)
        this.scene.add(this.sunLight)
		
        this.directional2 = new THREE.DirectionalLight('#000000', 0.);
        this.directional2.visible = false;
		this.scene.add(this.directional2);
        this.directional2.castShadow = false
        this.directional2.shadow.camera.near = 0.05
        this.directional2.shadow.camera.far = 100
        this.directional2.shadow.camera.left = m/-2
        this.directional2.shadow.camera.right = m/2
        this.directional2.shadow.camera.top = m/2
        this.directional2.shadow.camera.bottom = m/-2
        this.directional2.shadow.camera.updateProjectionMatrix()
        this.directional2.shadow.mapSize.set(1024*2, 1024*2)
        // normal bias
        this.directional2.shadow.normalBias = 0.03
        // bias needs to be negative to prevent banding in shadows
        this.directional2.shadow.bias = -0.003

        // Debug
        if(this.debug.active)
        {

            this.debugFolder.add(this.sunLight, 'castShadow').name('Shadow')

            this.debugFolder.addColor( this.sunLight, 'color').name('SunLight Color').listen()

            this.debugFolder
                .add(this.sunLight, 'intensity')
                .name('SunLight Intensity')
                .min(0)
                .max(20)
                .step(0.001)
            
            this.debugFolder
                .add(this.sunLight.position, 'x')
                .name('SunLight X')
                .min(- 50)
                .max(50)
                .step(0.1).listen()
            
            this.debugFolder
                .add(this.sunLight.position, 'y')
                .name('SunLight Y')
                .min(-50)
                .max(50)
                .step(0.1).listen()
            
            this.debugFolder
                .add(this.sunLight.position, 'z')
                .name('SunLight Z')
                .min(- 50)
                .max(50)
                .step(0.1).listen()

				
				
			this.debugFolder.addColor( this.directional2, 'color').name('SunLight Color 2').listen()
				this.debugFolder
                .add(this.directional2, 'intensity')
                .name('SunLight Intensity 2')
                .min(0)
                .max(20)
                .step(0.001)
            
            this.debugFolder
                .add(this.directional2.position, 'x')
                .name('SunLight X 2')
                .min(- 50)
                .max(50)
                .step(0.1).listen()
            
            this.debugFolder
                .add(this.directional2.position, 'y')
                .name('SunLight Y 2')
                .min(-50)
                .max(50)
                .step(0.1).listen()
            
            this.debugFolder
                .add(this.directional2.position, 'z')
                .name('SunLight Z 2')
                .min(- 50)
                .max(50)
                .step(0.1).listen()
        }
    }

    setEnvironmentMap(textureName,intensity)
    {
        this.environmentMap = {}
        this.environmentMap.intensity = intensity
        this.environmentMap.texture = this.resources.items.environmentMapTextureDawn
        this.environmentMap.texture.encoding = THREE.sRGBEncoding
        
        // this.debugFolder
        // .add(this.environmentMap, 'intensity')
        // .name('envMapIntensity')
        // .min(0)
        // .max(4)
        // .step(0.01)
        this.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterials = () =>
        {
            this.scene.traverse((child) =>
            {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                {
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }
        this.environmentMap.updateMaterials()
    }
}   

class Fog{
    constructor(scene,color,near,far,debug){
        // add fog
        var fogColor = new THREE.Color(color);
        // scene.background = new THREE.Color('white');; // Setting fogColor as the background color also
        scene.fog = new THREE.Fog(fogColor,near,far);
        var isActive = {active:true}

        // if(debug.active)
        // {
        //     this.folder = debug.ui.addFolder('Fog')
        //     this.folder.addColor( scene.fog, 'fogColor').name('FogColor')
        //     this.folder.add(scene.fog,'fogNear').name('Near').min(-50).max(100).step(0.1)
        //     this.folder.add(scene.fog, 'fogFar').name('Far').min(0).max(500).step(0.1)
        // }
    }
}


class Skybox
{
    constructor(scene,name,debug, experience){
        let materialArray = [];
        let textureloader = new THREE.TextureLoader()
        let texture_ft = textureloader.load( `textures/skyboxes/${ name }/ft.jpg`);
        texture_ft.encoding = THREE.sRGBEncoding
        let texture_bk = textureloader.load( `textures/skyboxes/${ name }/bk.jpg`);
        texture_bk.encoding = THREE.sRGBEncoding
        let texture_up = textureloader.load( `textures/skyboxes/${ name }/up.jpg`);
        texture_up.encoding = THREE.sRGBEncoding
        let texture_dn = textureloader.load( `textures/skyboxes/${ name }/dn.jpg`);
        texture_dn.encoding = THREE.sRGBEncoding
        let texture_rt = textureloader.load( `textures/skyboxes/${ name }/rt.jpg`);
        texture_rt.encoding = THREE.sRGBEncoding
        let texture_lf = textureloader.load( `textures/skyboxes/${ name }/lf.jpg`);
        texture_lf.encoding = THREE.sRGBEncoding
          
        let material  = new THREE.MeshBasicMaterial( { map: texture_ft })
        material.needsUpdate = true
        materialArray.push(material);
        materialArray.push(new THREE.MeshBasicMaterial( { map: texture_bk }));
        materialArray.push(new THREE.MeshBasicMaterial( { map: texture_up }));
        materialArray.push(new THREE.MeshBasicMaterial( { map: texture_dn }));
        materialArray.push(new THREE.MeshBasicMaterial( { map: texture_rt }));
        materialArray.push(new THREE.MeshBasicMaterial( { map: texture_lf }));
   
        for (let i = 0; i < 6; i++)
           {materialArray[i].side = THREE.BackSide;
           materialArray[i].fog = false}
        
        let skyboxGeo = new THREE.BoxBufferGeometry( 800, 600, 800);
        let skybox = new THREE.Mesh( skyboxGeo, materialArray );
        // for mountain morning
        if (name =='morning')
            {skybox.position.set(0,150,0)}

        //for night skybox
        if (name=='night')
            {skybox.position.set(0,0,0)}
        // scene.add( skybox );  
        this.skybox = skybox
        experience.renderer.update()
    }
}