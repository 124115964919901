import Experience from '../Experience.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import EventEmitter from '../Utils/EventEmitter.js'
import GUI from 'lil-gui'
import * as THREE from 'three'

import { storage } from "../Utils/FirebaseClient.js"
import { ref, getDownloadURL, listAll} from "firebase/storage";
import { CompressedPixelFormat } from 'three'

import { ToonMaterial } from '../materials/toonMaterial.js';

import axios from 'axios';

export default class Prop extends EventEmitter
{
    constructor(name,path,position,scale,rotation,lighten_color,model, texture_gcs_folder=null)
    {
        super()
        this.path = path
        this.position = position
        this.scale = scale
        this.rotation = rotation
        this.name = name
        this.lighten_color = lighten_color
        this.texture_gcs_folder = texture_gcs_folder
        this.toload = 0
        this.n = 0
        this.textures = []

        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug
        this.gltfLoader = new GLTFLoader()        

        this.gui = new GUI();
        this.gui.domElement.id = 'control_panel'
        this.gui.hide()

        if (this.path)
        {
            this.setModelFromPath() 
            this.on('ready', () =>
            {
                if (this.texture_gcs_folder){
                    this.setToggleTextures()
                }
            })
        }
        else 
        {
            this.fromModel(model)
        }

    }

    fromModel(model){
        this.model = model
        // this.model.scale.set(this.scale,this.scale,this.scale)
        // this.model.rotation.set(this.rotation[0],this.rotation[1],this.rotation[2])
        // this.model.position.set(this.position[0],this.position[1],this.position[2])

        // this.scene.add(this.model)

        this.model.traverse((child) =>
        {
            if(child.isMesh)
            {
                child.castShadow = true
                child.receiveShadow = true
                child.userData.parent = child
                child.geometry.computeVertexNormals()
            }
            if (child.material)
            {
                child.material = child.material.clone()
                child.material.metalness = 0.0
                if (child.material.opacity<1) {child.material.transparent = true}
                if (this.lighten_color)
                {
                    child.material.emissive.setHex( 0xffffff );
                    child.material.emissiveIntensity = 0.1
                }

                if (child.material.alphaTest > 0){ 
                    child.material.alphaMap = child.material.map
                    // child.material.map = undefined
                }

                child.userData.originalMaterial = child.material
                child.userData.originalTexture = child.material.map
                child.userData.animeMaterial = new ToonMaterial({colors: this.experience.shading.colors});
                child.userData.animeMaterial.copy(child.material);

                var params = {
                    color: '#ffffff',
                    overlayColor: '#ffffff',
                    ResetColor: function() {
                        if (child.userData.originalTexture != null)
                            {
                                child.material.map = child.userData.originalTexture
                                child.material.color.set(0xffffff)
                                child.material.needsUpdate = true
                                child.userData.animeMaterial.copy(child.userData.originalMaterial);
                                child.userData.animeMaterial.needsUpdate = true
                            }
                        this.experience.update()
                    }.bind(this),
                    UploadLayer: function() {
                        let fileElement = document.getElementById('fileInput')

                        // check if user had selected a file
                        if (fileElement.files.length === 0) {
                            alert('please choose a file')
                            return
                        }

                        let file = fileElement.files[0]

                        let formData = new FormData();
                        formData.set('file', file);

                        axios.post("http://localhost:3001/upload-single-file", formData, {
                        onUploadProgress: progressEvent => {
                            const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                            );
                            console.log(`upload process: ${percentCompleted}%`);
                        }
                        })
                        .then(res => {
                            console.log(res.data)
                            console.log(res.data.url)
                        })
                    },
                };

                var child_gui_parent = new GUI();
                child_gui_parent.domElement.id = 'control_panel'
                child_gui_parent.hide()

                var child_gui = child_gui_parent.addFolder(child.name)
                
                child_gui.addColor( params, 'color' ).name('Color').onChange(value => {
                    child.material.map = null
                    child.material.color.set(value)
                    child.material.needsUpdate = true
                    child.userData.animeMaterial.map = null
                    child.userData.animeMaterial.uniforms.uColor.value.copy(child.material.color)
                    child.userData.animeMaterial.needsUpdate = true
                    this.experience.update()
                });
                child_gui.addColor( params, 'overlayColor' ).name('overlayColor').onChange(value => {
                    child.material.map = child.userData.originalTexture
                    child.material.color.set(value)
                    child.material.needsUpdate = true
                    this.experience.update()
                }).onFinishChange(value => {
                    child.userData.animeMaterial.copy(child.userData.originalMaterial);
                    child.userData.animeMaterial.needsUpdate = true
                    this.experience.update()
                });

                child_gui.add( params, 'ResetColor' ); 
                child_gui.add( params, 'UploadLayer' ); 
                child.userData.thisGui = child_gui_parent
            
            }
        })
        this.trigger('ready')
    }

    setModelFromPath()
    {
        this.gltfLoader.load(
            this.path,
            (file) =>
            {
                this.model = file.scene
                this.model.scale.set(this.scale,this.scale,this.scale)
                this.model.rotation.set(this.rotation[0],this.rotation[1],this.rotation[2])
                this.model.position.set(this.position[0],this.position[1],this.position[2])

                this.scene.add(this.model)
                this.model.userData.gui = this.gui

                var numChildrenMesh = 0
                this.model.traverse((child) =>
                {
                    if(child.isMesh)
                    {
                        child.castShadow = true
                        child.receiveShadow = true
                        child.userData.parent = child
                        child.geometry.computeVertexNormals()
                    }
                    if (child.material)
                    {
                        numChildrenMesh = numChildrenMesh + 1
                        // var prevMaterial = child.material
                        // child.material = new THREE.MeshPhysicalMaterial();
                        // child.material.copy( prevMaterial )
                        child.material = child.material.clone()
                        child.material.metalness = 0.0
                        
                        // create toon material for anime shader
                        // console.log('creating toon material')
                        child.userData.originalMaterial = child.material
                        child.userData.animeMaterial = new ToonMaterial({colors: this.experience.shading.colors});
                        child.userData.animeMaterial.copy(child.material);

                        if (child.material.opacity<1)
                            {child.material.transparent = true}

                        if (this.lighten_color)
                        {
                            child.material.emissive.setHex( 0xffffff );
                            child.material.emissiveIntensity = 0.1
                        }

                        if (child.material.map != null){
                            // console.log('map image',child.material.map.image)
                            if (child.material.alphaTest > 0){ 
                                child.material.alphaMap = child.material.map
                            }
                            child.material.userData.hasOriginalMap = true
                            const original_texture = child.material.map
                            child.material.userData.originalTexture = original_texture
                        }
                        else{
                            child.material.userData.hasOriginalMap = false
                            child.material.userData.originalColor = child.material.color.clone()
                        }

                        var params = {
                            color: '#ffffff',
                            overlayColor: '#ffffff',
                            ResetColor: function() {
                                if (child.material.userData.hasOriginalMap){
                                    child.material.map = child.material.userData.originalTexture
                                    child.material.color.set(0xffffff)
                                    child.material.needsUpdate = true
                                    child.userData.animeMaterial.copy(child.userData.originalMaterial);
                                    child.userData.animeMaterial.needsUpdate = true
                                }
                                else{
                                    child.material.color.set(child.material.userData.originalColor)
                                    child.material.needsUpdate = true
                                    child.userData.animeMaterial.uniforms.uColor.value.copy(child.material.color)
                                    child.userData.animeMaterial.needsUpdate = true
                                }
                                this.experience.update()
                            }.bind(this),
                            UploadLayer: function() {
                                let fileElement = document.getElementById('fileInput')
        
                                // check if user had selected a file
                                if (fileElement.files.length === 0) {
                                    alert('please choose a file')
                                    return
                                }
        
                                let file = fileElement.files[0]
        
                                let formData = new FormData();
                                formData.set('file', file);
        
                                axios.post("http://localhost:3001/upload-single-file", formData, {
                                onUploadProgress: progressEvent => {
                                    const percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) / progressEvent.total
                                    );
                                    console.log(`upload process: ${percentCompleted}%`);
                                }
                                })
                                .then(res => {
                                    console.log(res.data)
                                    console.log(res.data.url)
                                })
                            },
                        };
        
                        var child_gui_parent = new GUI();
                        child_gui_parent.domElement.id = 'control_panel'
                        child_gui_parent.hide()

                        var child_gui = child_gui_parent.addFolder(child.name)
                        
                        child_gui.addColor( params, 'color' ).name('Color').onChange(value => {
                            child.material.map = null
                            child.material.color.set(value)
                            child.material.needsUpdate = true
                            child.userData.animeMaterial.map = null
                            child.userData.animeMaterial.uniforms.uColor.value.copy(child.material.color)
                            child.userData.animeMaterial.needsUpdate = true
                            this.experience.update()
                        });

                        child_gui.addColor( params, 'overlayColor' ).name('overlayColor').onChange(value => {
                            child.material.map = child.material.userData.originalTexture
                            child.material.needsUpdate = true
                            child.material.color.set(value)
                            this.experience.update()
                        }).onFinishChange(value => {
                            child.userData.animeMaterial.copy(child.userData.originalMaterial);
                            child.userData.animeMaterial.needsUpdate = true
                            this.experience.update()
                        });
                        child_gui.add( params, 'ResetColor' ); 
                        child_gui.add( params, 'UploadLayer' ); 


                        var textureparams = {
                            TogglePresets: this.togglePresets.bind(this),
                        };
                        
                        child_gui.add( textureparams, 'TogglePresets' ); 
                        
                        child.userData.thisGui = child_gui_parent
                    }
                })
                this.model.userData.numChildrenMesh = numChildrenMesh
                this.trigger('ready')
            }
        )
    }

    setToggleTextures()
    {
        this.loaded = 0
        this.textureLoader = new THREE.TextureLoader()

        const listRef = ref(storage, this.texture_gcs_folder);

        listAll(listRef)
             .then((res) => {
                const toload = res.items.length
                this.toload = toload
                res.items.forEach((itemRef) => {
                    var texture_path = itemRef._location.path_
                    getDownloadURL(ref(storage, texture_path))
                        .then((url) => {
                            this.loadTexture(url, toload)
                    })
                })
        })

        this.on('texturesLoaded', () =>
        {
            var textureparams = {
                TogglePresets: this.togglePresets.bind(this),
            };
            
            this.gui.add( textureparams, 'TogglePresets' ); 

        })
            
    }

    togglePresets() {
        if (this.textures.length ===0)
        {return}
        // console.log(this.n)
        this.model.traverse((child) =>
        {
            if (child.material)
            {
                if (child.material.userData.hasOriginalMap){
                    child.material.map = child.material.userData.originalTexture
                    child.material.map.image = this.textures[this.n % this.toload].image
                    child.material.map.needsUpdate = true
                    child.material.color.set(0xffffff)
                    child.material.needsUpdate = true
                    this.experience.update()
                }
            }
        })
        this.n = this.n+1
    }

    loadTexture(path, toload){
        this.textureLoader.load(
            // resource URL
            path,

            // onLoad callback
            function ( texture ) {
                texture.encoding = THREE.sRGBEncoding
                this.textures.push(texture)
                this.loaded++
                if(this.loaded == toload)
                {
                    this.trigger('texturesLoaded')
                }
            }.bind(this),

            // onProgress callback currently not supported
            undefined,

            // onError callback
            function ( err ) {
                console.error( 'Texture loading for prop', this.name, 'failed.' );
            })
    }

}

function uploadImage(){
    document.getElementById("uploadButton").onclick = () => {
        let fileElement = document.getElementById('fileInput')

        // check if user had selected a file
        if (fileElement.files.length === 0) {
        alert('please choose a file')
        return
        }

        let file = fileElement.files[0]

        let formData = new FormData();
        formData.set('file', file);

        axios.post("http://localhost:3001/upload-single-file", formData, {
        onUploadProgress: progressEvent => {
            const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(`upload process: ${percentCompleted}%`);
        }
        })
        .then(res => {
            console.log(res.data)
            console.log(res.data.url)
        })
    }
}