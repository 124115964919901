import * as THREE from 'three'
import Experience from './Experience.js'

import { OutlineEffect } from "three/examples/jsm/effects/OutlineEffect.js"
import { Post } from "./post-cartoon-i/post.js";
// import { Post } from "./post-cartoon-i/post-halftone.js"
import { PostBWManga } from './post-cartoon-i/post-bw-manga-demon-slayer.js';
import { PostOutline } from "./post-cartoon-i/post-outline.js";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { OutlinePostProcess } from './postprocessing/outline.js';

export default class Renderer
{
    constructor()
    {
        this.experience = new Experience()
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.shadingStyle = 'none'

        this.setInstance()
    }

    setInstance()
    {
        // https://attackingpixels.com/tips-tricks-optimizing-three-js-performance/
        // boosts performance
        let pixelRatio = window.devicePixelRatio
        let AA = true
        if (pixelRatio > 1) {
        AA = false
        }

        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: AA,
            alpha: true,
            preserveDrawingBuffer: true,
            powerPreference: "high-performance",
            logarithmicDepthBuffer: true
        })
        this.instance.physicallyCorrectLights = true
		let gl = this.instance.getContext()
		gl.getExtension('OES_standard_derivatives');
        // following two lines affect skybox colors
        this.instance.outputEncoding = THREE.sRGBEncoding
        this.instance.toneMapping = THREE.ACESFilmicToneMapping
        this.instance.toneMappingExposure = 1
        this.instance.shadowMap.enabled = true
        this.instance.shadowMap.type = THREE.PCFSoftShadowMap
        // this.instance.setClearColor('#000000')
        // this.instance.setClearColor( 0xffffff, 0)
        this.instance.setClearColor('#F5F5F5')
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))

        this.composer = new EffectComposer( this.instance );
        this.renderPass = new RenderPass( this.scene, this.camera.instance );
        this.composer.addPass( this.renderPass );
        this.outlinePass = new OutlinePass( new THREE.Vector2( window.innerWidth, window.innerHeight ), this.scene, this.camera.instance );
        this.composer.addPass( this.outlinePass );

        this.outlineEffect = new OutlineEffect(this.instance)
        this.outlineEffect.enabled = false

        this.post = new Post(this.instance);
        this.postbwmanga = new PostBWManga(this.instance);
        this.postOutline = new PostOutline(this.instance);

		this.postOutlineV2 = new OutlinePostProcess(this.instance, { 
			depthMultiplier: 10,
			depthBias: 1,
			outlineSize: 1,
			normalBias: 0.039,
			normalMultiplier: 0.3,
		})

		let folder = this.experience.debug.ui.addFolder('Outline');


		let uniform = this.postOutlineV2.uMultiplierParameters.value;
		
		folder.add(this.postOutlineV2.opts, 'outlineSize', 1, 10, 1).name("Outline Size").onChange(this.postOutlineV2.updateMaterial)

		folder.add(uniform, 'x', 0, 20, 0.01).name("Depth Bias")
		folder.add(uniform, 'y', 0, 80, 0.01).name("Depth Multiplier")
		folder.add(uniform, 'z', 0, 0.5, 0.001).name("Normal Bias")
		folder.add(uniform, 'w', 0, 1, 0.001).name("Normal Multiplier")
		
    }

    resize()
    {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
        this.composer.setSize(this.sizes.width, this.sizes.height)
        this.composer.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))

        const dPR = window.devicePixelRatio;
        this.post.setSize(this.sizes.width * dPR, this.sizes.height * dPR);
        this.postbwmanga.setSize(this.sizes.width * dPR, this.sizes.height * dPR);
		this.postOutlineV2.setSize(this.sizes.width * dPR, this.sizes.height * dPR)
	}

    update()
    {
        switch(this.shadingStyle) {
            case 'handSketch':
                this.resize()
                this.post.render(this.scene, this.camera.instance);
                break;
            case 'bwManga':
                this.resize()
                this.postbwmanga.render(this.scene, this.camera.instance)
                break;
            case 'classicAnime':
                this.resize()
                // this.instance.render(this.scene, this.camera.instance)
                this.outlineEffect.render(this.scene, this.camera.instance)
                // this.postOutline.render(this.scene, this.camera.instance)
                break;
			case 'outline':
				this.resize()
				// this.instance.render(this.scene, this.camera.instance)
				this.postOutlineV2.render(this.scene, this.camera.instance)
				// this.postOutline.render(this.scene, this.camera.instance)
				break;
            default:
                this.resize()
                // this.composer.render()
                this.instance.render(this.scene, this.camera.instance)
          } 
        // this.instance.render(this.scene, this.camera.instance)
        // this.instance.setAnimationLoop(update);
        // this.outlineEffect.render(this.scene, this.camera.instance)
    }
}