/*
Clicks to select object, highlight object
Then can transform, rotate selected object
Clicks anywhere else to deselect object
*/

import * as THREE from 'three'
import { Material, ShadowMaterial } from 'three'
import Experience from '../Experience.js'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import EventEmitter from './EventEmitter.js';
import { shader } from '../shaders/ortho-vs.js';
import { ToonMaterial } from '../materials/toonMaterial.js';

export default class Shading extends EventEmitter
{
    constructor()
    {
        super()

        this.experience = new Experience()
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        this.renderer = this.experience.renderer
        this.shadingActive = false
        this.ballpenshadingActive = false
        this.bwmangashadingActive = false
        this.cyberpunkActive = false

        this.colors = [
            {color: new THREE.Color('#a1a1a1'), start: 0., end: 0.499},
            {color: new THREE.Color('#ffffff'), start: 0.5, end: 1. },
        ]
        
        this.materialHolder = []
    }

    handSketchShading() {
        // if (this.shadingActive)
        // {
        //     this.undoShading();
        //     this.shadingActive = false
        // }

        this.renderer.shadingStyle = 'handSketch'
        this.experience.transform.deactivate();
        if (this.experience.poseManager.jointsVisible)
        {
            this.experience.poseManager.poseJoints()
        }
        this.trigger( 'change' );
    }

    bwMangaShading() {
		// if(true){
		// 	this.twoSidedToonShading()
		// 	return;
		// }
        // if (this.shadingActive)
        // {
        //     this.undoShading();
        //     this.shadingActive = false
        // }

        this.renderer.shadingStyle = 'bwManga'
        this.experience.transform.deactivate();
        if (this.experience.poseManager.jointsVisible)
        {
            this.experience.poseManager.poseJoints()
        }
        this.trigger( 'change' );
    }

    undobwMangaShading(){
        if (this.shadingActive)
        {
            this.undoShading();
            this.shadingActive = false
        }
        this.renderer.shadingStyle = 'none'
        this.trigger( 'change' );
    }

    undoHandSketchShading(){
        if (this.shadingActive)
        {
            this.undoShading();
            this.shadingActive = false
        }
        this.renderer.shadingStyle = 'none'
        this.trigger( 'change' );
    }

	twoSidedToonShading(){
        this.experience.transform.deactivate();
		this.experience.world.environment.sunLight.intensity = 8
		this.experience.world.environment.sunLight.color.set("#ff006a") 
		this.experience.world.environment.sunLight.position.x = 2;
		this.experience.world.environment.sunLight.position.z = -.8;
		this.experience.world.environment.sunLight.position.y = 0;

		this.experience.world.environment.directional2.intensity = 8
		this.experience.world.environment.directional2.color.set("#00ff4c") 
		this.experience.world.environment.directional2.position.x = -2;
		this.experience.world.environment.directional2.position.z = -0.8;
		this.experience.world.environment.directional2.position.y = 0;

		this.experience.world.environment.ambientLight.intensity = 1.7;
		this.experience.world.environment.ambientLight.color.set("#2e4dff");
		this.experience.world.environment.directional2.visible = true;
		let gradient = [
			{color: new THREE.Color('#000000'), start: 0., end: 0.499 },
			{color: new THREE.Color('#ffffff'), start: 0.55, end: 1 },
			// {color: new THREE.Color('#ff00ff'), start: 0.7, end: 1. },
		]
		this.simpleToonShading(gradient)
	}

	async simpleToonShading(gradient = null) {
		/*
			Gradients = [
				{color: THREE.Color, start: number, end: number}
			]
		*/
		let colors = gradient
		
		if(gradient == null){
			// Three Band Toon
			// colors = [
			// 	{color: new THREE.Color('#3b3b3b'), start: 0., end: 0.333},
			// 	{color: new THREE.Color('#8f8f8f'), start: 0.3334, end: 0.666 },
			// 	{color: new THREE.Color('#8f8f8f'), start: 0.6667, end: 1. },
			// ]
			// Two band Toon.
			colors = [
				{color: new THREE.Color('#5e5e5e'), start: 0., end: 0.499},
				{color: new THREE.Color('#ffffff'), start: 0.55, end: 1. },
			]
		}
        this.renderer.outlineEffect.enabled = false
        this.renderer.shadingStyle = 'classicAnime'
        this.renderer.shadingStyle = 'outline'
        this.shadowSoftness = {value:0}

        this.colors[0].color.copy(colors[0].color)
        this.colors[1].color.copy(colors[1].color)
		
        if (this.experience.world.shadeableObjectArray == []){
            return
        }

        // let shaded_objs = 0
        // let total_shading_objs = 0
		// this.experience.world.shadeableObjectArray.forEach(item => {
        //     total_shading_objs += item.userData.numChildrenMesh;
        // });
        // let progress = 0.01
        // return

        this.experience.world.shadeableObjectArray.forEach(item => {
            var childrenLength = 0
            item.traverse((child) =>
                {
                    if (child.material && !child.name.includes('rig')) {childrenLength += 1}
            })

            // var maxTimePerChunk = 200;
            // var index = 0;

            // function now() {
            //     return new Date().getTime();
            // }

            // function doChunk() {
            //     var startTime = now();
            //     while (index < childrenLength && (now() - startTime) <= maxTimePerChunk) {
            //         // callback called with args (value, index, array)
            //         fn.call(context, array[index], index, array);
            //         ++index;
            //     }
            //     if (index < array.length) {
            //         // set Timeout for async iteration
            //         setTimeout(doChunk, 1);
            //     }
            // }    
            // doChunk();    
  
            // var startTime = now();
            item.traverse((child) =>
                {
                    if(child instanceof THREE.Mesh)
                    {
                        // child.castShadow = false
                        // child.receiveShadow = true
                    }
                    
                    if (child.material && !child.name.includes('rig'))
                    {
                        // shaded_objs += 1
                        // progress = shaded_objs/total_shading_objs
                        // console.log(progress)

                        // loadingBgElement.innerHTML = "This scene is pretty large and KURUKURU is working hard ... (Don't close this window) The next time you click a filter it'll be instant. "+ Math.ceil(progress * 100)  + "%"
                        // loadingBarElement.style.transform = `scaleX(${progress})`

                        if (child.userData.originalMaterial == null)
                            {child.userData.originalMaterial = child.material}

                        // this.materialHolder.push(child.material)
						
                        // var prevMaterial = child.material
						if (child.material.alphaTest > 0.){
							// console.log('alphaTest > 0', child.geometry)
                            child.userData.outlineNormalDisable = true;
						}
						
                        if (child.userData.animeMaterial == null)
                        { 
                            child.material = new ToonMaterial({colors: this.colors});
                            child.userData.animeMaterial = child.material
                            child.material.copy(child.userData.originalMaterial);
                        }
                        else
                        {
                            child.userData.animeMaterial.uniforms.uColorRamp.value = this.colors
                            child.material = child.userData.animeMaterial
                        }
						
						// outline.manualOverride needs to be active for per-mesh change.
                        if (child.material.alphaMap != null){
							console.log('alphamap is not null:',child)
							// child.overrideMaterial = new THREE.MeshNormalMaterial({
							// 	alphaMap: child.material.alphaMap,
							// 	alphaTest: child.material.alphaTest,
							// })


                            // Found alpha map, disable normal outline rendering.
							child.userData.outlineNormalDisable = true;
							// console.log('overriding')
							// console.log(child.material.userData)

                            return
                        }
						
                    }
                
                }
            )
        })

        if(this.debug.active)
        {
            if (this.debugFolder != null){
                this.debug.ui.show()
            }
            else {
                this.debugFolder = this.debug.ui.addFolder("Filter Values");
                this.debug.ui.show()
                this.debugFolder.addColor(this.colors[0], "color").name("Dark Area").listen()
                this.debugFolder.addColor(this.colors[1], "color").name("Bright Area").listen()
                this.debugFolder.add(this.shadowSoftness, "value", 0.001, 0.5, 0.001).name('Shadow Softness').onChange( (value) => {
                    this.colors[1].start = 0.499 + value}).listen()
            }
        }

        if (this.experience.poseManager.jointsVisible)
        {
            this.experience.poseManager.poseJoints()
        }
        this.trigger( 'change' );
    }

    OLD_simpleToonShading() {
        // this.gradientTexture = this.resources.items.toonGradientTexture3
        // this.gradientTexture.minFilter = THREE.NearestFilter
        // this.gradientTexture.magFilter = THREE.NearestFilter
        // this.gradientTexture.generateMipmaps = false
        // this.renderer.outlineEffect.enabled = true
        // this.renderer.shadingStyle = 'classicAnime'
        // this.renderer.effectSobel.enabled = true

        this.experience.world.shadeableObjectArray.forEach(item => {
            item.traverse((child) =>
                {
                    if(child instanceof THREE.Mesh)
                    {
                        // child.castShadow = false
                        // child.receiveShadow = true
                    }
                    
                    if (child.material && !child.name.includes('rig'))
                    {
                        // if (child.material.alphaMap == undefined){
                        //     console.log('found alphamap')
                        //     return
                        // }
                        let outlineVisible = true
                        let tn = 0.003
                        if (child.material.userData.modelName == 'girl')
                            {
                                outlineVisible = false
                                tn = 0.003
                                child.material.displacementBias = -0.001
                                // child.material.displacementScale = 1
                                // child.material.clippingPlanes = null
                            }
                        this.materialHolder.push(child.material.clone())
                        var prevMaterial = child.material
                        child.material = new THREE.MeshToonMaterial();
						child.material.onBeforeCompile = (shader)=>{
							console.log(shader.fragmentShader)
							shader.fragmentShader = shader.fragmentShader.replace(
								'vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;',
							 "vec3 outgoingLight = reflectedLight.directDiffuse;")
							return shader;
						}
						console.log(child.material)
                        child.material.map = prevMaterial.map
                        child.material.alphaMap = prevMaterial.alphaMap

                        if (prevMaterial instanceof THREE.MeshStandardMaterial)
                        {
                            try {
                                child.material.copy( prevMaterial )
                                child.material.alphaMap = prevMaterial.alphaMap
                            } catch(e){
                                console.log('prevMaterial',prevMaterial)
                                console.log(child)
                                console.error(e)
                            }
                        }

                        if (child.userData.modelName!='girl')
                        {
                            child.material.gradientMap = this.gradientTexture
                        }
                        
                        child.material.userData.outlineParameters = {
                                thickness: tn,
                                color: [ 0, 0, 0 ],
                                alpha: 0.8,
                                visible: outlineVisible,
                        };
                    }
                
                }
            )
        })
        this.experience.transform.deactivate();
        if (this.experience.poseManager.jointsVisible)
        {
            this.experience.poseManager.poseJoints()
        }
        this.trigger( 'change' );
    }

    resetShading(){
        if (this.cyberpunkActive || this.shadingActive){
            this.undoShading()
            this.cyberpunkActive = false
            this.shadingActive = false
        }
        if (this.bwmangashadingActive)
        {
            this.undobwMangaShading()
            this.bwmangashadingActive = false
        }
        if (this.ballpenshadingActive) {
            this.undoHandSketchShading()
            this.ballpenshadingActive = false
        }
        this.trigger('change')
    }

    undoShading(){
		if(this.debugFolder ){
			this.debugFolder.destroy();
			this.debugFolder = null;
		}
        this.debug.ui.hide()
		this.experience.world.environment.directional2.visible = false;

        this.experience.world.shadeableObjectArray.forEach(item => {
            // console.log(item)
            item.traverse((child) =>
                {
                    if (child instanceof THREE.Mesh)
                    {
                        child.castShadow = true
                    }
                    if (child.material && !child.name.includes('rig'))
                    {
                        if (child.userData.originalMaterial != null)
                            {child.material = child.userData.originalMaterial}
                    }
                
                }
            )
        })
        this.renderer.shadingStyle = 'none'
        this.experience.world.environment.morninglight()
		this.trigger( 'change' );


    }
}